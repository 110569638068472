import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import { toast } from "react-toastify";

export default function CreateProduct() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    Object.keys(inputs).forEach((key) => {
      formData.append(key, inputs[key]);
    });
    if (file) {
      formData.append("image1", file);
    }

    try {
      await axios.post(
        "https://www.yutel.az/yutel/dashboard.php/save",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate(-1);
      toast("Yeni məhsul əlavə olundu");
    } catch (error) {
      console.error("Error while saving the product:", error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="max-w-4xl mx-auto my-10 px-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">Məhsul əlavə et</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-4 ">
            {/* {[
              "name",
              "description",
              "description1",
              "description2",
              "description3",
              "description4",
              "description5",
              "description6",
              "description7",
              "description8",
              "description9",
              "description10",
              "description11",
              "description12",
              "description13",
              "description14",
              "description15",
              "question1",
              "answer1",
              "question2",
              "answer2",
              "question3",
              "answer3",
              "question4",
              "answer4",
              "question5",
              "answer5",
              "question6",
              "answer6",
              "question7",
              "answer7",
              "question8",
              "answer8",
              "question9",
              "answer9",
              "question10",
              "answer10",
              "question11",
              "answer11",
              "question12",
              "answer12",
              "question13",
              "answer13",
              "question14",
              "answer14",
              "question15",
              "answer15",
              "apply1",
              "apply2",
              "apply3",
              "apply4",
              "apply5",
            ].map((field, index) => (
              <div key={index}>
                <label className="block text-gray-600">{field}:</label>
                <input
                  type="text"
                  name={field}
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>
            ))} */}

                 <div >
                <label className="block text-gray-600">Ad:</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Məhsul ümümi təsviri:</label>
                <input
                  type="text"
                  name="description"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 1:</label>
                <input
                  type="text"
                  name="description1"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 2:</label>
                <input
                  type="text"
                  name="description2"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 3:</label>
                <input
                  type="text"
                  name="description3"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 4:</label>
                <input
                  type="text"
                  name="description4"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 5:</label>
                <input
                  type="text"
                  name="description5"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 6:</label>
                <input
                  type="text"
                  name="description6"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 7:</label>
                <input
                  type="text"
                  name="description7"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 8:</label>
                <input
                  type="text"
                  name="description8"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 9:</label>
                <input
                  type="text"
                  name="description9"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 10:</label>
                <input
                  type="text"
                  name="description10"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 11:</label>
                <input
                  type="text"
                  name="description11"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 12:</label>
                <input
                  type="text"
                  name="description12"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 13:</label>
                <input
                  type="text"
                  name="description13"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 14:</label>
                <input
                  type="text"
                  name="description14"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Təsvir 15:</label>
                <input
                  type="text"
                  name="description15"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 1:</label>
                <input
                  type="text"
                  name="question1"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 1:</label>
                <input
                  type="text"
                  name="answer1"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 2:</label>
                <input
                  type="text"
                  name="question2"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 2:</label>
                <input
                  type="text"
                  name="answer2"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 3:</label>
                <input
                  type="text"
                  name="question3"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 3:</label>
                <input
                  type="text"
                  name="answer3"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 4:</label>
                <input
                  type="text"
                  name="question4"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 4:</label>
                <input
                  type="text"
                  name="answer4"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 5:</label>
                <input
                  type="text"
                  name="question5"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 5:</label>
                <input
                  type="text"
                  name="answer5"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 6:</label>
                <input
                  type="text"
                  name="question6"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 6:</label>
                <input
                  type="text"
                  name="answer6"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>


              <div >
                <label className="block text-gray-600">Özəllik 7:</label>
                <input
                  type="text"
                  name="question7"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 7:</label>
                <input
                  type="text"
                  name="answer7"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 8:</label>
                <input
                  type="text"
                  name="question8"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 8:</label>
                <input
                  type="text"
                  name="answer8"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 9:</label>
                <input
                  type="text"
                  name="question9"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 9:</label>
                <input
                  type="text"
                  name="answer9"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 10:</label>
                <input
                  type="text"
                  name="question10"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 10:</label>
                <input
                  type="text"
                  name="answer10"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 11:</label>
                <input
                  type="text"
                  name="question11"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 11:</label>
                <input
                  type="text"
                  name="answer11"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>


              <div >
                <label className="block text-gray-600">Özəllik 12:</label>
                <input
                  type="text"
                  name="question12"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 12:</label>
                <input
                  type="text"
                  name="answer12"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 13:</label>
                <input
                  type="text"
                  name="question13"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 13:</label>
                <input
                  type="text"
                  name="answer13"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 14:</label>
                <input
                  type="text"
                  name="question14"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 14:</label>
                <input
                  type="text"
                  name="answer14"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Özəllik 15:</label>
                <input
                  type="text"
                  name="question15"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Cavab 15:</label>
                <input
                  type="text"
                  name="answer15"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Tətbiq 1:</label>
                <input
                  type="text"
                  name="apply1"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Tətbiq 2:</label>
                <input
                  type="text"
                  name="apply2"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Tətbiq 3:</label>
                <input
                  type="text"
                  name="apply3"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Tətbiq 4:</label>
                <input
                  type="text"
                  name="apply4"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              <div >
                <label className="block text-gray-600">Tətbiq 5:</label>
                <input
                  type="text"
                  name="apply5"
                  onChange={handleChange}
                  
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                />
              </div>

              
          </div>
          <div>
            <label className="block text-gray-600">Kateqoriya 1:</label>
            <select
              name="category1"
              onChange={handleChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            >
              <option value="">Seçin</option>
              <option value="ups">UPS</option>
              <option value="fiber-optik">Fiber Optik</option>
              <option value="copper">Copper (Mis)</option>
              <option value="kabinler">Kabinlər</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-600">Kateqoriya 2:</label>
            <select
              name="category2"
              onChange={handleChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            >
              <option value="">Seçin</option>
              <option value="3faza-1faza">3 Faza Giriş - 1 Faza Çıxış</option>
              <option value="3faza-3faza">3 Faza Giriş - 3 Faza Çıxış</option>
              <option value="tekfazali">Tək fazalı</option>
              <option value="indoor">Kabinlər (İndoor Floor)</option>
              <option value="outdoor">Kabinlər (Outdoor Floor)</option>
              <option value="indoor-wall">Divar tipli kabinlər (İndoor Wall)</option>
              <option value="outdoor-wall">Divar tipli kabinlər (Outdoor Wall)</option>
              <option value="pdu">PDU</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="kabel-menecmentler">Kabel Menecmentlər</option>
              <option value="fanlar">Fanlar</option>
              <option value="refler">Rəflər</option>
              <option value="kabeller">Kabellər</option>
              <option value="alet-aksesuarlar">Alətlər və Aksesuarlar</option>
              <option value="patch-panel">Patch Panellər</option>
              <option value="divar-tipli">Divar tipli yuvalar</option>
              <option value="poe-injektor">PoE injektor</option>
              <option value="komponentler">Komponentlər</option>
              <option value="mdc">MDC</option>
              <option value="mtp-mpo">MTP-MPO</option>
              <option value="fiber-optik-kabinler">Fiber optik kabinlər</option>
              <option value="aktiv">Aktiv</option>
              <option value="alet-avadanliq">Alətlər/Avadanlıqlar</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-600">Kateqoriya 3:</label>
            <select
              name="category3"
              onChange={handleChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            >
              <option value="">Seçin</option>
              <option value="cat8">Cat 8</option>
              <option value="cat7">Cat 7</option>
              <option value="cat6a">Cat 6a</option>
              <option value="cat6">Cat 6</option>
              <option value="cat5e">Cat 5e</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="aletler">Alətlər</option>
              <option value="konnektorlar">Konnektorlar</option>
              <option value="alet-cantalari">Alət Çantaları</option>
              <option value="adaptorlar">Adaptorlar & Konnektorlar</option>
              <option value="patch-kordlar">Patch Kordlar</option>
              <option value="pitgailler">Fiber Optik Pitgaillər</option>
              <option value="splitterler">Splitterlər</option>
              <option value="komponentler">MTP-MTO Komponentlər</option>
              <option value="patch-paneller">MTP-MTP Patch Panellər</option>
              <option value="temizleyici-alet">Təmizləyici Alətlər</option>
              <option value="fo-aletler">FO Alətlər</option>
              <option value="ffth">FTTH Aktiv Avadanlıqlar</option>
              <option value="oeo">OEO</option>
              <option value="poe">PoE</option>
              <option value="media">Media Konverter Şassiləri</option>
              <option value="sfb">SFB Transiverlər</option>
              <option value="tek-lifli">Tək Lifli Fiber RJ45</option>
              <option value="cut-lifli">Cüt Lifli Fiber RJ45</option>
              <option value="svitcler">Svitçlər</option>
              <option value="kabinler">Kabinlər</option>
              <option value="paylayici-qutular">Paylayıcı Qutular</option>
              <option value="muftalar">Muftalar</option>
              <option value="patch-paneller">Patch Panellər</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="women">Fiber Optik</option>
              <option value="unisex">Copper</option>
              <option value="unisex">Kabinlər</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-600">Kateqoriya 4:</label>
            <select
              name="category4"
              onChange={handleChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            >
              <option value="">Seçin</option>
              <option value="kabeller">Kabellər</option>
              <option value="ksj">KSJ</option>
              <option value="patch-kordlar">Patch Kordlar</option>
              <option value="patch-paneller">Patch Panellər</option>
              <option value="konnektorlar">Konnektorlar</option>
              <option value="keystone">Keystone Jacks</option>
              <option value="indoor">İndoor</option>
              <option value="outdoor">Outdoor</option>
              <option value="sixici">Sıxıcı Alətlər</option>
              <option value="parcalayici">Parçalayıcı Alətlər</option>
              <option value="fiber">Fiber Alignment</option>
              <option value="kesici">Kəsici Alətlər</option>
              <option value="otdr">OTDR</option>
              <option value="mikroskop">Mikroskop</option>
              <option value="identifikator">İdentifikator</option>
              <option value="guc-olcen">Güc Ölçən</option>
              <option value="vfl">VFL</option>
              <option value="splice">Splice Machines</option>
              <option value="alet-destleri">Alət dəstləri</option>
              <option value="1u">1U</option>
              <option value="2u">2U</option>
              <option value="3u">3U</option>
              <option value="4u">4U</option>
              <option value="dome">(Dome) Muftalar</option>
              <option value="inline">(İnline) Muftalar</option>
              <option value="ffth">(FTTH BPEO) Muftalar</option>
              <option value="paylayici">İndoor (Paylayıcı Qutular)</option>
              <option value="splitter">İndoor (Splitter Qutular)</option>
              <option value="terminal">İndoor (Terminal Qutular)</option>
              <option value="outdoor-paylayici">Outdoor (Paylayıcı Qutular)</option>
              <option value="outdoor-splitter">Outdoor (Splitter Qutular)</option>
              <option value="outdoor-terminal">Outdoor (Terminal Qutular)</option>


            </select>
          </div>
          <div>
            <label className="block text-gray-600">Kateqoriya 5:</label>
            <select
              name="category5"
              onChange={handleChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            >
              <option value="">Seçin</option>
              <option value="plastik">Plastik</option>
              <option value="metal">Metal</option>
              <option value="heat">(Heat Shrink) Muftalar</option>
              <option value="mechanical">(Mechanical) Muftalar</option>
              <option value="mechanical">İnline Mechanical</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-600">Şəkil:</label>
            <input
              type="file"
              name="image1"
              accept="image/*"
              onChange={handleFileChange}
              
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="text-right">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-200"
            >
              Yadda saxla
            </button>
          </div>
        </form>
      </div>
    </>
  );
}