import React from 'react';
import { about, about1, about2, certificates, hero1, hero2, hero9 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Shop = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
        <div
              className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[400px] relative"
              style={{
                backgroundImage: `url(${hero9})`,
              }}
            >
              <div className="absolute inset-0 bg-blue-500 opacity-50"></div> {/* Mavi renkli katman */}
              <p className="text-2xl md:text-6xl font-bold text-white max-w-containerSm md:max-w-container mx-auto pt-[93px] md:pt-[170px] relative z-20">
                {t("Mağaza")}
              </p>
            </div>

    </>
  );
};

export default Shop;
