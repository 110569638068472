import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";

export default function DashboardContact() {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.yutel.az/yutel/contact.php/", {
        withCredentials: false
    })
      .then(function (response) {
        setProducts(response.data);
      });
  }

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://www.yutel.az/yutel/contact.php/${id}`);
      if (response.data.status === 1) {
        console.log("Product deleted successfully");
        getProducts()
      } else {
        console.error("Failed to delete product:", response.data.message);
      }
    } catch (error) {
      console.error("Error while deleting the product:", error);
    }
  };
  
  
  return (
    <>
    <DashboardHeader/>
        <div className="max-w-containerSm md:max-w-container mx-auto py-20">
          <table className="bg-[white]">
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Telefon</th>
                <th>Email</th>
                <th>Mesaj</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    {/* <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Link> */}
                    <button onClick={() => handleDelete(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{user.email}</td>
                  <td>{user.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </> 
  );
}
