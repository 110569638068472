import React from 'react';
import './About.css';
import { Carousel2, category1, certificates, contact, logo } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

const Contact = () => {

  const { t, i18n } = useTranslation();
  
  return (
    <div className='mx-auto mt-[76px] md:mt-[108px] h-[474px] mb-[104px] md:mb-[160px]'>
      <div 
        className='bg-contain bg-no-repeat bg-center pl-[72px] pt-[42px] h-[474px]' 
        style={{ backgroundImage: `url(${contact})` }} // Burada arka plan resmini belirtiyoruz
      >
        <p className='text-[white] text-[41px] font-bold bg-opacity-50 p-4 md:w-[1064px] mx-auto text-center leading-[65px] mt-[72px]'>
        {t("Məhsul və qiymətlərimiz barədə ətraflı məlumat almaq üçün bizimlə əlaqə saxlayın")}
        </p>
        <div className='flex justify-center items-center'>
      <a href='/products'><button className='mt-[42px] h-[50px] bg-[#4285f4] rounded text-white px-4'>+994 12 460 75 00</button></a>
      </div>
      </div>
    </div>
  );
}

export default Contact;
