import React from 'react';
import { hero1, hero7, partner1, partner2, partner3, partner4, partner5, partner6 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Partners = () => {

      const { t, i18n } = useTranslation();

  return (
    <>
       <div
        className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[400px] relative"
        style={{
          backgroundImage: `url(${hero7})`,
        }}
      >
        <div className="absolute inset-0 bg-blue-500 opacity-50"></div> {/* Mavi renkli katman */}
        <p className="text-2xl md:text-6xl font-bold text-white max-w-containerSm md:max-w-container mx-auto pt-[93px] md:pt-[170px] relative z-20">
          {t("Partnyorlar")}
        </p>
      </div>
      <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
  <div className='flex flex-col justify-center items-center'>
      <p className='text-[#101621] text-3xl md:text-[50px] font-bold'>{t("Tərəfdaşlarımız")}</p>
      <p className='mt-[38px] text-[#101621] text-base font-semibold text-center'>
      {t("Fəaliyyət müddətimiz ərzində şirkətimiz BRITICOM, NOKIA, UNIFY, NEC, HP, Juniper, Cisco Systems, Enterasys, Bolid, IBM, Polycom, Optex, GKB, TOPCAM, BOSCH Securities, KABA, ASOT, MITEL, Uptime Institute, CANOVATE kimi qlobal istehsalçı şirkətlərlə güclü tərəfdaşlıq qurmuşdur.")}
      </p>
    </div>
</div>
<div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px] mb-[104px] md:mb-[160px]'>
  <div className='grid grid-cols-4'>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner1}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner2}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner3}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner4}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner5}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner6}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner1}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner2}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner3}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner4}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner5}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner6}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner1}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner2}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner3}></img></div>
  <div> <img className='h-[157px] w-[282px] border border-gray-200' src={partner4}></img></div>
  </div>
</div>

    </>
  );
};

export default Partners;
