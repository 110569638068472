import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {logo } from "../../../assets/images/index";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";

export default function Dashboard() {

  const [isLoading, setIsLoading] = useState(true)

  const allBrands = [
    "ups", "fiber-optik", "copper", "kabinler"
  ];
  
  const categories = allBrands.map(brand => ({
    name: brand,
    img: 'logo',  // Buraya markanın logosunun görsel yolunu ekleyin
    path: `/category/${brand}`  // Markadaki boşlukları '-' ile değiştirip küçük harfe dönüştürerek dinamik path oluşturuluyor
  }));
  
  console.log(categories);
  
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <>
    <DashboardHeader/>
    <div className="max-w-containerSm md:max-w-container mx-auto py-14">
      <p className="text-[40px] font-semibold">Məhsullar</p>
      <div className="gap-4 mt-5" >
      <a href="/dashboard/category/ups"> <button  
            type="submit"
             className=" bg-[#2D3E50] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">UPS</button></a>
              <a href="/dashboard/category/fiber-optik"> <button  
            type="submit"
             className=" bg-[#2D3E50] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">Fiber Optik</button></a>
              <a href="/dashboard/category/copper"> <button  
            type="submit"
             className=" bg-[#2D3E50] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">Copper(mis)</button></a>
              <a href="/dashboard/category/kabinler"> <button  
            type="submit"
             className=" bg-[#2D3E50] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">Kabinlər</button></a>
  </div>
  </div>
    </>
  );
}