import React from 'react';
import './About.css';
import { Carousel2, category1, category2, category3, category4, category5, category6, certificates, logo } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const Category = () => {

    const { t, i18n } = useTranslation();
  
  return (
   <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
    <div className='grid md:grid-cols-3 gap-6'>
    <div className='md:w-[360px] md:h-[360px] bg-[#F0F0F0] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category1} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>UPS</p>
    <a href='/products/ups'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
<div className='md:w-[360px] md:h-[360px] bg-[#BFDBE7] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category2} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>Fiber optik</p>
    <a href='/products/fiber-optik'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
<div className='md:w-[360px] md:h-[360px] bg-[#c4d7dd] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category3} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>Mis</p>
    <a href='/products/copper'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
<div className='md:w-[360px] md:h-[360px] bg-[#dfdfeb] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category4} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>Kabinlər</p>
    <a href='/products/kabinler'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
<div className='md:w-[360px] md:h-[360px] bg-[#E8EADD] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category5} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>Kabellər</p>
    <a href='/products/copper/kabeller'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
<div className='md:w-[360px] md:h-[360px] bg-[#c1e2de] p-6 flex justify-center items-center relative'>
  <img className='h-auto w-[320px] object-cover' src={category6} alt="Category" />
  <div className='absolute bottom-0 left-0 p-4 text-white'>
    <p className='text-[#101621] text-2xl font-semibold'>Fanlar</p>
    <a href='/products/kabinler/fanlar'><p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p></a>
  </div>
</div>
    </div>
   </div>
  );
}

export default Category;
