import React from 'react';
import { facebook, instagram, partner1, partner2, partner3, partner4, partner5, partner6, payment, whatsapp } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t, i18n } = useTranslation();


  return (
    <footer className="bg-[#2D3E50]   text-white">
      <div className="max-w-containerSm md:max-w-containerBig md:px-4 mx-auto grid grid-cols-1 md:grid-cols-4 gap-[108px] py-8">
        <div>
          <p className="text-2xl font-semibold">{t("BİZİMLƏ ƏLAQƏ")}</p>
          <p className='text-lg mt-5'>{t("YUTEL MMC")}</p>
          <p className='text-lg mt-5'>{t("Chinar Park İş Mərkəzi, bina 2, mərtəbə 5 otaq 508, Əhməd Rəcəbli küçəsi 1/25 Bakı, AZ1075, Azərbaycan")}</p>
          <p className='text-lg mt-5'>{t("E-poçt")}: info@yutel.az</p>
          <p className='text-lg mt-5'>Mob: +994 12 460 75 00</p>
        </div>
        <div>
          <h4 className="text-2xl font-semibold">{t("PARTNYORLAR")}</h4>
          <div className='grid grid-cols-2 gap-5 mt-5'>
           <div>
            <img className='h-auto w-[143px]' src={partner1}></img>
           </div>
           <div>
            <img className='h-auto w-[143px]' src={partner2}></img>
           </div>
           <div>
            <img className='h-auto w-[143px]' src={partner3}></img>
           </div>
           <div>
            <img className='h-auto w-[143px]' src={partner4}></img>
           </div>
           <div>
            <img className='h-auto w-[143px]' src={partner5}></img>
           </div>
           <div>
            <img className='h-auto w-[143px]' src={partner6}></img>
           </div>
          </div>
        </div>
        <div>
          <h4 className="text-2xl font-semibold">{t("ÖDƏNİŞ NÖVLƏRİ")}</h4>
          <img className='mt-5' src={payment}></img>
        </div>
        <div>
          <p className="text-2xl font-semibold">{t("SOSİAL ŞƏBƏKƏLƏR")}</p>
          <div className='flex gap-2 mt-5'>
            <img className='w-[64px] h-[64px]' src={whatsapp}></img>
            <img className='w-[64px] h-[64px]' src={facebook}></img>
            <img className='w-[64px] h-[64px]' src={instagram}></img>
          </div>
        </div>
      </div>
      <div className='bg-[gray] h-[1px] max-w-containerSm md:max-w-containerBig md:px-4 mx-auto'></div>
      <div className="max-w-containerSm md:max-w-containerBig md:px-4 md:flex justify-between mx-auto py-4 text-sm">
        <p>Yutel.az © {t("Bütün hüquqlar qorunur.")}</p>
        <p>{t("Sayt hazırlandı")}: <a href='https://birsayt.az/'>Birsayt.az</a></p>
      </div>
    </footer>
  );
};

export default Footer;
