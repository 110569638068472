import React from 'react';
import './About.css';
import { Carousel2, certificates, certificates1, certificates2, certificates3, logo } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const Certificates = () => {

    const { t, i18n } = useTranslation();

  return (
   <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
    <div className='grid md:grid-cols-3'>
   <div className='flex'>
     <div className='h-[80px] w-[80px]'>
     <img className='h-[80px] w-[80px] object-cover' src={certificates1}></img>
     </div>
     <div className='md:ml-4'>
      <p className='text-[#101621] text-sm font-semibold'>{t("Şirkət sertifikatı ISO 9001")}</p>
      <p className='mt-2 text-[#575a5f] text-xs font-normal leading-[23px]'>{t("Keyfiyyətin idarə edilməsi üzrə dünyada ən məşhur standart ISO 9001-dir.")}</p>
     </div>
   </div>
   <div className='flex'>
     <div className='h-[80px] w-[80px]'>
     <img className='h-[80px] w-[80px] object-cover' src={certificates2}></img>
     </div>
     <div className='md:ml-4'>
      <p className='text-[#101621] text-sm font-semibold'>{t("Uyğunluq və ətraf mühit")}</p>
      <p className='mt-2 text-[#575a5f] text-xs font-normal leading-[23px]'>{t("Məhdudiyyətə (RoHS) əsaslanan məhsul səviyyəsində uyğunluqdur.")}</p>
     </div>
   </div>
   <div className='flex'>
     <div className='h-[80px] w-[80px]'>
     <img className='h-[80px] w-[80px] object-cover' src={certificates3}></img>
     </div>
     <div className='md:ml-4'>
      <p className='text-[#101621] text-sm font-semibold'>{t("Üçüncü şəxs sertifikatları")}</p>
      <p className='mt-2 text-[#575a5f] text-xs font-normal leading-[23px]'>{t("Kabel sənayesi üçün müstəqil akkreditə olunmuş sertifikatlaşdırma orqanıdır.")}</p>
     </div>
   </div>
    </div>
   </div>
  );
}

export default Certificates;
