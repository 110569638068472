export const categories = [

    //Category 1
 {
   name: "UPS",
   link: "/ups",
   subcategories: [
     { name: "3 Faza Giriş - 1 Faza Çıxış", link: "/ups/3faza-1faza" },
     { name: "3 Faza Giriş - 3 Faza Çıxış", link: "/ups/3faza-3faza" },
     { name: "Tək fazalı", link: "/ups/tekfazali" },
   ],
 },


    //Category 2
 {
   name: "Fiber Optik",
   link: "/fiber-optik",
   subcategories: [
     {
       name: "Komponentlər",
       link: "/fiber-optik/komponentler",
       subcategories: [
         { name: "Adaptorlar & Konnektorlar", link: "/fiber-optik/komponentler/adaptorlar" },
         {
           name: "Patch Kordlar",
           link: "/fiber-optik/komponentler/patch-kordlar",
           subcategories: [
             { name: "İndoor", link: "/fiber-optik/komponentler/patch-kordlar/indoor" },
             { name: "Outdoor", link: "/fiber-optik/komponentler/patch-kordlar/outdoor" },
           ],
         },
         { name: "Fiber Optik Pitgaillər", link: "/fiber-optik/komponentler/pitgailler" },
         { name: "Splitterlər", link: "/fiber-optik/komponentler/splitterler" },
       ],
     },
     { name: "MDC", link: "/fiber-optik/mdc" },
     {
       name: "MTP-MPO",
       link: "/fiber-optik/mtp-mpo",
       subcategories: [
         { name: "MTP-MTO Komponentlər", link: "/fiber-optik/mtp-mpo/komponentler" },
         { name: "MTP-MTP Patch Panellər", link: "/fiber-optik/mtp-mpo/patch-paneller" },
       ],
     },
     {
         name: "Fiber optik kabinlər",
         link: "/fiber-optik/fiber-optik-kabinler",
         subcategories: [
             {
                 name: "Kabinlər",
                 link: "/fiber-optik/fiber-optik-kabinler/kabinler",
                 subcategories: [
                   { name: "İndoor", link: "/fiber-optik/fiber-optik-kabinler/kabinler/indoor" },
                   { name: "Outdoor", link: "/fiber-optik/fiber-optik-kabinler/kabinler/outdoor" },
                 ],
               },
               {
                 name: "Paylayıcı Qutular",
                 link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular",
                 subcategories: [
                     {
                         name: "İndoor (Paylayıcı Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici/metal" },
                         ],
                       },
                       {
                         name: "İndoor (Splitter Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter/metal" },
                         ],
                       },
                       {
                         name: "İndoor (Terminal Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal/metal" },
                         ],
                       },
                       {
                         name: "Outdoor (Paylayıcı Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici/metal" },
                         ],
                       },
                       {
                         name: "Outdoor (Splitter Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter/metal" },
                         ],
                       },
                       {
                         name: "Outdoor (Terminal Qutular)",
                         link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal",
                         subcategories: [
                           { name: "Plastik", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal/plastik" },
                           { name: "Metal", link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal/metal" },
                         ],
                       },
                 ],
               },
               {
                 name: "Muftalar",
                 link: "/fiber-optik/fiber-optik-kabinler/muftalar",
                 subcategories: [
                     {
                         name: "(Dome) Muftalar",
                         link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome",
                         subcategories: [
                           { name: "(Heat Shrink) Muftalar", link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome/heat" },
                           { name: "(Mechanical) Muftalar", link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome/mechanical" },
                         ],
                       },
                       {
                         name: "(İnline) Muftalar",
                         link: "/fiber-optik/fiber-optik-kabinler/muftalar/inline",
                         subcategories: [
                           { name: "İnline Mechanical", link: "/fiber-optik/fiber-optik-kabinler/muftalar/inline/mechanical" },
                         ],
                       },
                       {
                         name: "(FTTH BPEO) Muftalar",
                         link: "/fiber-optik/fiber-optik-kabinler/muftalar/ffth",
                       },
                       
                 ],
               },
               {
                 name: "Patch Panellər",
                 link: "/fiber-optik/fiber-optik-kabinler/patch-paneller",
                 subcategories: [
                   { name: "1U", link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/1u" },
                   { name: "2U", link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/2u" },
                   { name: "3U", link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/3u" },
                   { name: "4U", link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/4u" },
                 ],
               },
               {
                 name: "Aksesuarlar",
                 link: "/fiber-optik/fiber-optik-kabinler/aksesuarlar",
               },
         ],
       },
       {
         name: "Aktiv",
         link: "/fiber-optik/aktiv",
         subcategories: [
           { name: "FTTH Aktiv Avadanlıqlar", link: "/fiber-optik/aktiv/ffth" },
           { name: "OEO", link: "/fiber-optik/aktiv/oeo" },
           { name: "PoE", link: "/fiber-optik/aktiv/poe" },
           { name: "Media Konverter Şassiləri", link: "/fiber-optik/aktiv/media" },
           { name: "SFB Transiverlər", link: "/fiber-optik/aktiv/sfb" },
           { name: "Tək Lifli Fiber RJ45", link: "/fiber-optik/aktiv/tek-lifli" },
           { name: "Cüt Lifli Fiber RJ45", link: "/fiber-optik/aktiv/cut-lifli" },
           { name: "Svitçlər", link: "/fiber-optik/aktiv/svitcler" },
         ],
       },
       {
         name: "Alətlər/Avadanlıqlar",
         link: "/fiber-optik/alet-avadanliq",
         subcategories: [
           { name: "Təmizləyici Alətlər", link: "/fiber-optik/alet-avadanliq/temizleyici-alet" },
           {
             name: "FO Alətlər",
             link: "/fiber-optik/alet-avadanliq/fo-aletler",
             subcategories: [
               { name: "Sıxıcı Alətlər", link: "/fiber-optik/alet-avadanliq/fo-aletler/sixici" },
               { name: "Parçalayıcı Alətlər", link: "/fiber-optik/alet-avadanliq/fo-aletler/parcalayici" },
               { name: "Fiber Alignment", link: "/fiber-optik/alet-avadanliq/fo-aletler/fiber" },
               { name: "Kəsici Alətlər", link: "/fiber-optik/alet-avadanliq/fo-aletler/kesici" },
               { name: "OTDR", link: "/fiber-optik/alet-avadanliq/fo-aletler/otdr" },
               { name: "Mikroskop", link: "/fiber-optik/alet-avadanliq/fo-aletler/mikroskop" },
               { name: "İdentifikator", link: "/fiber-optik/alet-avadanliq/fo-aletler/identifikator" },
               { name: "Güc Ölçən", link: "/fiber-optik/alet-avadanliq/fo-aletler/guc-olcen" },
               { name: "VFL", link: "/fiber-optik/alet-avadanliq/fo-aletler/vfl" },
               { name: "Splice Machines", link: "/fiber-optik/alet-avadanliq/fo-aletler/splice" },
               { name: "Alət dəstləri", link: "/fiber-optik/alet-avadanliq/fo-aletler/alet-destleri" },
               
             ],
           },
           { name: "Fiber Optik Pitgaillər", link: "/fiber-optik/alet-avadanliq/pitgailler" },
           { name: "Splitterlər", link: "/fiber-optik/alet-avadanliq/splitterler" },
         ],
       },
   ],
 },


        //Category 3
 {
     name: "Copper (Mis)",
     link: "/copper",
     subcategories: [
       {
         name: "Kabellər",
         link: "/copper/kabeller",
         subcategories: [
             {
                 name: "Cat 8",
                 link: "/copper/kabeller/cat8",
                 subcategories: [
                   { name: "Kabellər", link: "/copper/kabeller/cat8/kabeller" },
                   { name: "KSJ", link: "/copper/kabeller/cat8/ksj" },
                   { name: "Patch Kordlar", link: "/copper/kabeller/cat8/patch-kordlar" },
                   { name: "Patch Panellər", link: "/copper/kabeller/cat8/patch-paneller" },
                   { name: "Konnektorlar", link: "/copper/kabeller/cat8/konnektorlar" },
                 ],
               },
               {
                 name: "Cat 7",
                 link: "/copper/kabeller/cat7",
                 subcategories: [
                   { name: "Kabellər", link: "/copper/kabeller/cat7/kabeller" },
                   { name: "KSJ", link: "/copper/kabeller/cat7/ksj" },
                   { name: "Patch Kordlar", link: "/copper/kabeller/cat7/patch-kordlar" },
                   { name: "Patch Panellər", link: "/copper/kabeller/cat7/patch-paneller" },
                   { name: "Konnektorlar", link: "/copper/kabeller/cat7/konnektorlar" },
                 ],
               },
               {
                 name: "Cat 6a",
                 link: "/copper/kabeller/cat6a",
                 subcategories: [
                   { name: "Kabellər", link: "/copper/kabeller/cat6a/kabeller" },
                   { name: "Keystone Jacks", link: "/copper/kabeller/cat6a/keystone" },
                   { name: "Konnektorlar", link: "/copper/kabeller/cat6a/konnektorlar" },
                   { name: "Patch Kordlar", link: "/copper/kabeller/cat6a/patch-kordlar" },
                   { name: "Patch Panellər", link: "/copper/kabeller/cat6a/patch-paneller" },
                 ],
               },
               {
                 name: "Cat 6",
                 link: "/copper/kabeller/cat6",
                 subcategories: [
                   { name: "Kabellər", link: "/copper/kabeller/cat6/kabeller" },
                   { name: "Keystone Jacks", link: "/copper/kabeller/cat6/keystone" },
                   { name: "Konnektorlar", link: "/copper/kabeller/cat6/konnektorlar" },
                   { name: "Patch Kordlar", link: "/copper/kabeller/cat6/patch-kordlar" },
                   { name: "Patch Panellər", link: "/copper/kabeller/cat6/patch-paneller" },
                 ],
               },
               {
                 name: "Cat 5e",
                 link: "/copper/kabeller/cat5e",
                 subcategories: [
                   { name: "Kabellər", link: "/copper/kabeller/cat5e/kabeller" },
                   { name: "Keystone Jacks", link: "/copper/kabeller/cat5e/keystone" },
                   { name: "Konnektorlar", link: "/copper/kabeller/cat5e/konnektorlar" },
                   { name: "Patch Kordlar", link: "/copper/kabeller/cat5e/patch-kordlar" },
                   { name: "Patch Panellər", link: "/copper/kabeller/cat5e/patch-paneller" },
                 ],
               },
         ],
       },
       {
         name: "Alətlər və Aksesuarlar",
         link: "/copper/alet-aksesuarlar",
         subcategories: [
           { name: "Aksesuarlar", link: "/copper/alet-aksesuarlar/aksesuarlar" },
           { name: "Alətlər", link: "/copper/alet-aksesuarlar/aletler" },
           { name: "Konnektorlar", link: "/copper/alet-aksesuarlar/konnektorlar" },
           { name: "Alət Çantaları", link: "/copper/alet-aksesuarlar/alet-cantalari" },
         ],
       },
       { name: "Patch Panellər", link: "/copper/patch-panel" },
       { name: "Divar tipli yuvalar", link: "/copper/divar-tipli" },
       { name: "PoE injektor", link: "/copper/poe-injektor" },
     ],
   },



//Category 4
   {
     name: "Kabinlər",
     link: "/kabinler",
     subcategories: [
       { name: "Kabinlər (İndoor Floor)", link: "/kabinler/indoor" },
       { name: "Kabinlər (Outdoor Floor)", link: "/kabinler/outdoor" },
       { name: "Divar tipli kabinlər (İndoor Wall)", link: "/kabinler/indoor-wall" },
       { name: "Divar tipli kabinlər (Outdoor Wall)", link: "/kabinler/outdoor-wall" },
       { name: "PDU", link: "/kabinler/pdu" },
       { name: "Aksesuarlar", link: "/kabinler/aksesuarlar" },
       { name: "Kabel Menecmentlər", link: "/kabinler/kabel-menecmentler" },
       { name: "Fanlar", link: "/kabinler/fanlar" },
       { name: "Rəflər", link: "/kabinler/refler" },
     ],
   },
];