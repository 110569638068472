import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import Categories from './Categories';
import { useParams } from "react-router-dom";
import {categories} from '../data/categories'
import { useTranslation } from "react-i18next";

const ProductsSub3 = () => {

      const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { category1, category2, category3 } = useParams();



  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php`,
        { withCredentials: false }
      );

      const filteredProducts = response.data.data.filter(
        (product) =>
          product.category1 === category1 &&
          product.category2 === category2 &&
          product.category3 === category3
      );
      setProducts(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

 const findCategoryName = (item) => {
      for (const category of categories) {
        if (category.link.split("/").pop() === item) {
          return t(category.name);
        }
        for (const subcategory of category.subcategories) {
          if (subcategory.link.split("/").pop() === item) {
            return t(subcategory.name);
          }
        }
      }
      return t(item); // Eğer eşleşme yoksa orijinal değeri döndür
    };

  return (
    <>
      <div className="bg-[#F7FAFC] pb-[104px] md:pb-[160px]">
        <div className="max-w-containerSm md:max-w-containerBig px-4 mx-auto pt-[76px] md:pt-[108px]">
          <div className="md:flex mx-auto mt-8 gap-8 justify-between">
            {/* Left Filter Section */}
            <div className="md:w-[363px]">
              <h4 className="text-black text-xl font-medium">{t("Məhsul kateqoriyaları")}</h4>
              <Categories />
            </div>

            {/* Products Section */}
            <div className="md:w-[1026px]">
              <p className="mb-5">
              {t("Məhsullar")} / {findCategoryName(category1)} / {findCategoryName(category2)} / {findCategoryName(category3)}
              </p>
              {isLoading ? (
                <p className="text-[white]">Loading...</p>
              ) : (
                <PaginatedItems itemsPerPage={15} items={products} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsSub3;





















































