import React, { useEffect, useState } from 'react';
import { category1 } from '../../../assets/images'; // Gerekli görselleri burada kullanıyoruz.
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Products = () => {

      const { t, i18n } = useTranslation();

  const [category, setCategory] = useState('fiber-optik');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php`,
        { withCredentials: false }
      );

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const getBackgroundColor = (index) => {
    const colors = ['#F0F0F0', '#BFDBE7', '#c4d7dd', '#dfdfeb', '#E8EADD', '#c1e2de']; // Sample colors
    return colors[index % colors.length]; // This ensures that if there are more than 6 products, it will loop through the colors
  };
  

  const filteredProducts = products.filter(product => product.category1 === category).slice(0, 6);

  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
      <p className='text-center text-[#101621] text-[50px] font-bold'>{t("Məhsullarımız")}</p>

      {/* Seçim butonları */}
      <div className='flex justify-center space-x-8 mt-9'>
        <button
          onClick={() => setCategory('fiber-optik')}
          className={`text-lg font-semibold ${category === 'fiber-optik' ? 'rounded-3xl border border-[#eaeaeb] px-2' : 'text-[#101621]'}`}
        >
          Fiber optik
        </button>
        <button
          onClick={() => setCategory('ups')}
          className={`text-lg font-semibold ${category === 'ups' ? 'rounded-3xl border border-[#eaeaeb] px-2' : 'text-[#101621]'}`}
        >
          UPS
        </button>
      </div>

      {/* Ürünler */}
      <div className='grid md:grid-cols-3 gap-6 mt-9'>
      {filteredProducts.map((product, index) => (
  <div
    key={product.id}
    className={`md:w-[360px] md:min-h-[420px] p-6 flex justify-center items-center relative shadow`}
  >
    <img className='h-auto w-[320px] object-cover' src={`https://www.yutel.az/yutel/${product.image1}`} alt="Category" />
    <div className='absolute bottom-0 left-0 p-4 text-white'>
      <p className='text-[#101621] text-2xl font-semibold'>{product.name.slice(0, 25)}</p>
      <a href='/products'>
        <p className='text-[#101621] text-sm font-semibold'>{t("Hamısına bax")}</p>
      </a>
    </div>
  </div>
))}


      </div>
      <div className='flex justify-center items-center'>
        <a href='/products'><button className='mt-[72px] h-[50px] bg-[#4285f4] rounded text-white px-4'>{t("Bütün məhsullara bax")}</button></a>
      </div>
    </div>
  );
};

export default Products;
