import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";

const Pagination = () => {
  const { category1 } = useParams();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, [category1]);

  const getProducts = async () => {
    try {
      const response = await axios.get(`https://www.yutel.az/yutel/dashboard.php`);
      const filteredProducts = response.data.data.filter(
        (product) => product.category1 === category1
      );
      setItems(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (!window.confirm("Bu məhsulu silmək istədiyinizə əminsiniz?")) return;
    try {
      await axios.delete(`https://www.yutel.az/yutel/dashboard.php?id=${id}`);
      setItems(items.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="max-w-6xl mx-auto py-10 px-4">
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">Məhsullar</h2>

        {isLoading ? (
          <p className="text-center text-gray-500">Yüklənir...</p>
        ) : items.length === 0 ? (
          <p className="text-center text-gray-500">Məhsul tapılmadı.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-200">
                <tr className="text-gray-600 text-left">
                  <th className="p-3">ID</th>
                  <th className="p-3">Ad</th>
                  <th className="p-3 text-center">Əməliyyatlar</th>
                </tr>
              </thead>
              <tbody>
                {items.map((user) => (
                  <tr key={user.id} className="border-b hover:bg-gray-100">
                    <td className="p-3">{user.id}</td>
                    <td className="p-3">{user.name}</td>
                    <td className="p-3 flex justify-center space-x-4">
                      <Link
                        to={`/dashboard/${user.id}/edit`}
                        className="bg-blue-600 text-white px-3 py-1 rounded-lg hover:bg-blue-700 transition"
                      >
                        Yenilə
                      </Link>
                      <button
                        onClick={() => deleteUser(user.id)}
                        className="bg-red-600 text-white px-3 py-1 rounded-lg hover:bg-red-700 transition"
                      >
                        Sil
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Pagination;
