import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import { carousel1, carousel2, carousel3, hero1, hero2, hero3, hero4 } from "../../../assets/images";
import './Carousel.css';

const Carousel = () => {

  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: hero4, text: "TANURA ilə Hər Gün Təravətli Dəri", content: "Həkim-kosmetoloq və kosmetoloqlar üçün nəzərdə tutulmuş məhsullar" },
    { img: hero3, text: "TANURA ilə Hər Gün Təravətli Dəri", content: "Həkim-kosmetoloq və kosmetoloqlar üçün nəzərdə tutulmuş məhsullar" },
    { img: hero2, text: "TANURA ilə Hər Gün Təravətli Dəri", content: "Həkim-kosmetoloq və kosmetoloqlar üçün nəzərdə tutulmuş məhsullar" },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <div className="relative">
      {/* Header is placed outside the Swiper for it to stay on top */}
      <Swiper
        spaceBetween={0}  // Ensure no space between slides
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        onSlideChange={(swiper) => {
          setShowText(false);
          setShowButton(false);
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="w-full">
             <div
                         className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[500px] relative"
                         style={{
                           backgroundImage: `url(${slide.img})`,
                         }}
                       >
                         <div className="absolute inset-0 bg-blue-500 opacity-30"></div> {/* Mavi renkli katman */}
                       </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
