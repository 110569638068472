import React from 'react';
import Discount from '../../Components/home/Discount/Discount';
import Slider from '../../Components/home/Slider/Slider';
import Products from '../../Components/home/Products/Products';
import Poster from '../../Components/home/Poster/Poster';
import Preferences from '../../Components/home/Preferences/Preferences';
import Carousel from '../../Components/home/Carousel/Carousel'
import BrandSlider from '../../Components/home/BrandSlider/BrandSlider';
import About from '../../Components/home/About/About';
import Category from '../../Components/home/Category/Category';
import Certificates from '../../Components/home/Certificates/Certificates';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Contact from '../../Components/home/Contact/Contact';



const Home = () => {
  return (
    <>
         <Carousel/>
         <Certificates/>
         <Category/>
    <Products/>
    <WhyUs/>
    <Contact/>

    
    </>
  )
}

export default Home;
