import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [product, setProduct] = useState({
    name: '',
    description: '',
    description1: '',
    description2: '',
    description3: '',
    description4: '',
    description5: '',
    description6: '',
    description7: '', 
    description8: '',
    description9: '',
    description10: '',
    description11: '',
    description12: '',
    description13: '',
    description14: '',
    description15: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
    question9: '',
    question10: '',
    question11: '',
    question12: '',
    question13: '',
    question14: '',
    question15: '',

    answer1: '',
    answer2: '',
    answer3: '',
    answer4: '',
    answer5: '',
    answer6: '',
    answer7: '',
    answer8: '',
    answer9: '',
    answer10: '',
    answer11: '',
    answer12: '',
    answer13: '',
    answer14: '',
    answer15: '',

    apply1: '',
    apply2: '',
    apply3: '',
    apply4: '',
    apply5: '',

    category1: '',
    category2: '',
    category3: '',
    category4: '',
    category5: '',
  }); 

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProductById();
  }, [id]);

  const getProductById = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php?id=${id}`
      );
      const fetchedProduct = response.data.data[0];
      setProduct(fetchedProduct);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const productData = {
      id,
      name: product.name,
      description: product.description,
      description1: product.description1,
      description2: product.description2,
      description3: product.description3,
      description4: product.description4,
      description5: product.description5,
      description6: product.description6,
      description7: product.description7,
      description8: product.description8,
      description9: product.description9,
      description10: product.description10,
      description11: product.description11,
      description12: product.description12,
      description13: product.description13,
      description14: product.description14,
      description15: product.description15,
      question1: product.question1,
      question2: product.question2,
      question3: product.question3,
      question4: product.question4,
      question5: product.question5,
      question6: product.question6,
      question7: product.question7,
      question8: product.question8,
      question9: product.question9,
      question10: product.question10,
      question11: product.question11,
      question12: product.question12,
      question13: product.question13,
      question14: product.question14,
      question15: product.question15,
      answer1: product.answer1,
      answer2: product.answer2,
      answer3: product.answer3,
      answer4: product.answer4,
      answer5: product.answer5,
      answer6: product.answer6,
      answer7: product.answer7,
      answer8: product.answer8,
      answer9: product.answer9,
      answer10: product.answer10,
      answer11: product.answer11,
      answer12: product.answer12,
      answer13: product.answer13,
      answer14: product.answer14,
      answer15: product.answer15,
      category1: product.category1,
      category2: product.category2,
      category3: product.category3,
      category4: product.category4,
      category5: product.category5,
      apply1: product.apply1,
      apply2: product.apply2,
      apply3: product.apply3,
      apply4: product.apply4,
      apply5: product.apply5,
    };

    try {
      const response = await axios.put(
        `https://www.yutel.az/yutel/dashboard.php`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        alert("Məhsul yeniləndi!");
        navigate(-1);
      } else {
        alert("Error updating product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating product");
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg my-10">
      {isLoading ? (
        <p className="text-center text-gray-500">Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <h2 className="text-xl font-semibold text-gray-700 border-b pb-2">
            Məhsulu yenilə
          </h2>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Ad
            </label>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Məhsul ümümi təsviri
            </label>
            <input
              type="text"
              name="description"
              value={product.description}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Təsvir 1
            </label>
            <input
              type="text"
              name="description1"
              value={product.description1}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 2
            </label>
            <input
              type="text"
              name="description2"
              value={product.description2}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 3
            </label>
            <input
              type="text"
              name="description3"
              value={product.description3}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 4
            </label>
            <input
              type="text"
              name="description4"
              value={product.description4}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 5
            </label>
            <input
              type="text"
              name="description5"
              value={product.description5}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 6
            </label>
            <input
              type="text"
              name="description6"
              value={product.description6}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 7
            </label>
            <input
              type="text"
              name="description7"
              value={product.description7}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 8
            </label>
            <input
              type="text"
              name="description8"
              value={product.description8}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 9
            </label>
            <input
              type="text"
              name="description9"
              value={product.description9}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 10
            </label>
            <input
              type="text"
              name="description10"
              value={product.description10}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 11
            </label>
            <input
              type="text"
              name="description11"
              value={product.description11}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 12
            </label>
            <input
              type="text"
              name="description12"
              value={product.description12}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 13
            </label>
            <input
              type="text"
              name="description13"
              value={product.description13}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 14
            </label>
            <input
              type="text"
              name="description14"
              value={product.description14}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Təsvir 15
            </label>
            <input
              type="text"
              name="description15"
              value={product.description15}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Özəllik 1
            </label>
            <input
              type="text"
              name="question1"
              value={product.question1}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Cavab 1
            </label>
            <input
              type="text"
              name="answer1"
              value={product.answer1}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 2
            </label>
            <input
              type="text"
              name="question2"
              value={product.question2}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 2
            </label>
            <input
              type="text"
              name="answer2"
              value={product.answer2}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 3
            </label>
            <input
              type="text"
              name="question3"
              value={product.question3}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 3
            </label>
            <input
              type="text"
              name="answer3"
              value={product.answer3}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 4
            </label>
            <input
              type="text"
              name="question4"
              value={product.question4}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 4
            </label>
            <input
              type="text"
              name="answer4"
              value={product.answer4}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 5
            </label>
            <input
              type="text"
              name="question5"
              value={product.question5}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 5
            </label>
            <input
              type="text"
              name="answer5"
              value={product.answer5}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 6
            </label>
            <input
              type="text"
              name="question6"
              value={product.question6}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 6
            </label>
            <input
              type="text"
              name="answer6"
              value={product.answer6}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 7
            </label>
            <input
              type="text"
              name="question7"
              value={product.question7}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          
          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 7
            </label>
            <input
              type="text"
              name="answer7"
              value={product.answer7}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 8
            </label>
            <input
              type="text"
              name="question8"
              value={product.question8}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 8
            </label>
            <input
              type="text"
              name="answer8"
              value={product.answer8}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 9
            </label>
            <input
              type="text"
              name="question9"
              value={product.question9}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 9
            </label>
            <input
              type="text"
              name="answer9"
              value={product.answer9}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 10
            </label>
            <input
              type="text"
              name="question10"
              value={product.question10}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 10
            </label>
            <input
              type="text"
              name="answer10"
              value={product.answer10}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 11
            </label>
            <input
              type="text"
              name="question11"
              value={product.question11}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 11
            </label>
            <input
              type="text"
              name="answer11"
              value={product.answer11}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 12
            </label>
            <input
              type="text"
              name="question12"
              value={product.question12}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 12
            </label>
            <input
              type="text"
              name="answer12"
              value={product.answer12}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 13
            </label>
            <input
              type="text"
              name="question13"
              value={product.question13}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 13
            </label>
            <input
              type="text"
              name="answer13"
              value={product.answer13}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 14
            </label>
            <input
              type="text"
              name="question14"
              value={product.question14}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 14
            </label>
            <input
              type="text"
              name="answer14"
              value={product.answer14}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Özəllik 15
            </label>
            <input
              type="text"
              name="question15"
              value={product.question15}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
            Cavab 15
            </label>
            <input
              type="text"
              name="answer15"
              value={product.answer15}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Tətbiq 1
            </label>
            <input
              type="text"
              name="apply1"
              value={product.apply1}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Tətbiq 2
            </label>
            <input
              type="text"
              name="apply2"
              value={product.apply2}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Tətbiq 3
            </label>
            <input
              type="text"
              name="apply3"
              value={product.apply3}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Tətbiq 4
            </label>
            <input
              type="text"
              name="apply4"
              value={product.apply4}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Tətbiq 5
            </label>
            <input
              type="text"
              name="apply5"
              value={product.apply5}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            />
          </div>


          <div>
            <label className="block text-sm font-medium text-gray-600">
              Kateqoriya 1
            </label>
            <select
              name="category1"
              value={product.category1}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            >
              <option value="">Seçin</option>
              <option value="ups">UPS</option>
              <option value="fiber-optik">Fiber Optik</option>
              <option value="copper">Copper (Mis)</option>
              <option value="kabinler">Kabinlər</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Kateqoriya 2
            </label>
            <select
              name="category2"
              value={product.category2}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            >
              <option value="">Seçin</option>
              <option value="3faza-1faza">3 Faza Giriş - 1 Faza Çıxış</option>
              <option value="3faza-3faza">3 Faza Giriş - 3 Faza Çıxış</option>
              <option value="tekfazali">Tək fazalı</option>
              <option value="indoor">Kabinlər (İndoor Floor)</option>
              <option value="outdoor">Kabinlər (Outdoor Floor)</option>
              <option value="indoor-wall">Divar tipli kabinlər (İndoor Wall)</option>
              <option value="outdoor-wall">Divar tipli kabinlər (Outdoor Wall)</option>
              <option value="pdu">PDU</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="kabel-menecmentler">Kabel Menecmentlər</option>
              <option value="fanlar">Fanlar</option>
              <option value="refler">Rəflər</option>
              <option value="kabeller">Kabellər</option>
              <option value="alet-aksesuarlar">Alətlər və Aksesuarlar</option>
              <option value="patch-panel">Patch Panellər</option>
              <option value="divar-tipli">Divar tipli yuvalar</option>
              <option value="poe-injektor">PoE injektor</option>
              <option value="komponentler">Komponentlər</option>
              <option value="mdc">MDC</option>
              <option value="mtp-mpo">MTP-MPO</option>
              <option value="fiber-optik-kabinler">Fiber optik kabinlər</option>
              <option value="aktiv">Aktiv</option>
              <option value="alet-avadanliq">Alətlər/Avadanlıqlar</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-600">
              Kateqoriya 3
            </label>
            <select
              name="category3"
              value={product.category3}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            >
             <option value="">Seçin</option>
              <option value="cat8">Cat 8</option>
              <option value="cat7">Cat 7</option>
              <option value="cat6a">Cat 6a</option>
              <option value="cat6">Cat 6</option>
              <option value="cat5e">Cat 5e</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="aletler">Alətlər</option>
              <option value="konnektorlar">Konnektorlar</option>
              <option value="alet-cantalari">Alət Çantaları</option>
              <option value="adaptorlar">Adaptorlar & Konnektorlar</option>
              <option value="patch-kordlar">Patch Kordlar</option>
              <option value="pitgailler">Fiber Optik Pitgaillər</option>
              <option value="splitterler">Splitterlər</option>
              <option value="komponentler">MTP-MTO Komponentlər</option>
              <option value="patch-paneller">MTP-MTP Patch Panellər</option>
              <option value="temizleyici-alet">Təmizləyici Alətlər</option>
              <option value="fo-aletler">FO Alətlər</option>
              <option value="ffth">FTTH Aktiv Avadanlıqlar</option>
              <option value="oeo">OEO</option>
              <option value="poe">PoE</option>
              <option value="media">Media Konverter Şassiləri</option>
              <option value="sfb">SFB Transiverlər</option>
              <option value="tek-lifli">Tək Lifli Fiber RJ45</option>
              <option value="cut-lifli">Cüt Lifli Fiber RJ45</option>
              <option value="svitcler">Svitçlər</option>
              <option value="kabinler">Kabinlər</option>
              <option value="paylayici-qutular">Paylayıcı Qutular</option>
              <option value="muftalar">Muftalar</option>
              <option value="patch-paneller">Patch Panellər</option>
              <option value="aksesuarlar">Aksesuarlar</option>
              <option value="women">Fiber Optik</option>
              <option value="unisex">Copper</option>
              <option value="unisex">Kabinlər</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Kateqoriya 4
            </label>
            <select
              name="category4"
              value={product.category4}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            >
               <option value="">Seçin</option>
              <option value="kabeller">Kabellər</option>
              <option value="ksj">KSJ</option>
              <option value="patch-kordlar">Patch Kordlar</option>
              <option value="patch-paneller">Patch Panellər</option>
              <option value="konnektorlar">Konnektorlar</option>
              <option value="keystone">Keystone Jacks</option>
              <option value="indoor">İndoor</option>
              <option value="outdoor">Outdoor</option>
              <option value="sixici">Sıxıcı Alətlər</option>
              <option value="parcalayici">Parçalayıcı Alətlər</option>
              <option value="fiber">Fiber Alignment</option>
              <option value="kesici">Kəsici Alətlər</option>
              <option value="otdr">OTDR</option>
              <option value="mikroskop">Mikroskop</option>
              <option value="identifikator">İdentifikator</option>
              <option value="guc-olcen">Güc Ölçən</option>
              <option value="vfl">VFL</option>
              <option value="splice">Splice Machines</option>
              <option value="alet-destleri">Alət dəstləri</option>
              <option value="1u">1U</option>
              <option value="2u">2U</option>
              <option value="3u">3U</option>
              <option value="4u">4U</option>
              <option value="dome">(Dome) Muftalar</option>
              <option value="inline">(İnline) Muftalar</option>
              <option value="ffth">(FTTH BPEO) Muftalar</option>
              <option value="paylayici">İndoor (Paylayıcı Qutular)</option>
              <option value="splitter">İndoor (Splitter Qutular)</option>
              <option value="terminal">İndoor (Terminal Qutular)</option>
              <option value="outdoor-paylayici">Outdoor (Paylayıcı Qutular)</option>
              <option value="outdoor-splitter">Outdoor (Splitter Qutular)</option>
              <option value="outdoor-terminal">Outdoor (Terminal Qutular)</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Kateqoriya 5
            </label>
            <select
              name="category5"
              value={product.category5}
              onChange={handleChange}
              className="mt-1 w-full p-2 border rounded-lg focus:ring focus:ring-blue-300"
            >
            <option value="">Seçin</option>
              <option value="plastik">Plastik</option>
              <option value="metal">Metal</option>
              <option value="heat">(Heat Shrink) Muftalar</option>
              <option value="mechanical">(Mechanical) Muftalar</option>
              <option value="mechanical">İnline Mechanical</option>
            </select>
          </div>


          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
            >
              Yenilə
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
