import React from 'react';
import { about, about1, about2, certificates, hero1, hero2, hero6 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Contact = () => {

      const { t, i18n } = useTranslation();

  return (
    <>
   <div
  className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[400px] relative"
  style={{
    backgroundImage: `url(${hero6})`,
  }}
>
  <div className="absolute inset-0 bg-blue-500 opacity-50"></div> {/* Mavi renkli katman */}
  <p className="text-2xl md:text-6xl font-bold text-white max-w-containerSm md:max-w-container mx-auto pt-[93px] md:pt-[170px] relative z-20">
    {t("Bizimlə əlaqə")}
  </p>
</div>




      <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div className='md:w-[497px]'>
      <p className='text-[#101621] text-2xl font-bold '>{t("'İşıq sürətində bağlantılar: optik kabellərimizlə fikrinizdəki dünya!'")}</p>
      <p className='mt-[32px] text-[#101621] text-base'>
      {t("Böyük Britaniyada yerləşən mis kabel sistemləri, fiber-optik passiv məhsullar, datakomlar, məlumat mərkəzləri, FTTx, telekommunikasiya, yayım və ağıllı ev proqramlarında yerləşdirilən rəflər və korpuslar istehsalçısı axtarırsınız?  YUTEL MMC ilə əlaqə saxlayın!  Bizim həllərimiz yüksək keyfiyyət və modulluq, innovativ funksionallıq və quraşdırma, həmçinin texniki xidmətdə müstəsna rahatlığı ilə heyran edir.")}
      </p>
    </div>

    <div className="md:w-[553px] mt-[80px] md:mt-0 ">
     <img src={hero1}></img>
    </div>
  </div>
</div>
<div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px] h-[373px] bg-[#F4F4F4] p-8 mb-[104px] md:mb-[160px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div className='md:w-[353px]'>
      <p className='text-[#101621] text-2xl font-bold '>{t("Ofisimiz")}</p>
      <p className='mt-[32px] text-[#101621] text-base'>
      {t("Ünvan: Chinar Park İş Mərkəzi, bina 2, mərtəbə 5 otaq 508, Əhməd Rəcəbli küçəsi 1/25 Bakı, AZ1075, Azərbaycan")}
      </p>
      <p className='mt-[32px] text-[#101621] text-base'>{t("Telefon:")} +994 12 460 75 00</p>
      <p className='mt-[32px] text-[#101621] text-base'>{t("E-ünvan:")} info@yutel.az</p>
    </div>

    <div className="md:w-[553px] mt-[80px] md:mt-0 h-[620px]">
     <img src={hero1}></img>
    </div>
  </div>
</div>

    </>
  );
};

export default Contact;
