import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Products from "./Pages/Products/Products";
  import ProductDetail from "./Pages/ProductDetail/ProductDetail";
  import Protected from "./Pages/admin/Protected/Protected";
  import Dashboard from "./Pages/admin/Dashboard/Dashboard";
  import AdminPanel from "./Pages/admin/AdminPanel/AdminPanel";
import CreateUser from "./Pages/admin/CreateUser/CreateUser";
import DashboardContact from "./Pages/admin/DashboardContact/DashboardContact";
import DashboardOffer from "./Pages/admin/DashboardOffer/DashboardOffer"
import DashboardOrders from "./Pages/admin/DashboardOrders/DashboardOrders";
import EditUser from "./Pages/admin/EditUser/EditUser";
import Partners from "./Pages/Partners/Partners";
import Solutions from "./Pages/Solutions/Solutions";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import ProductsSub1 from "./Pages/ProductsSub1/ProductsSub1";
import ProductsSub2 from "./Pages/ProductsSub2/ProductsSub2";
import ProductsSub3 from "./Pages/ProductsSub3/ProductsSub3";
import ProductsSub4 from "./Pages/ProductsSub4/ProductsSub4";
import ProductsSub5 from "./Pages/ProductsSub5/ProductsSub5";
import Shop from "./Pages/Shop/Shop";
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <AboveHeader/>
        <Header />
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/about" element={ <About/>}></Route>
          <Route path="/solutions" element={ <Solutions/>}></Route>
          <Route path="/partners" element={ <Partners/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>
          <Route path="/products" element={ <Products/>}></Route>
          <Route path="/shop" element={ <Shop/>}></Route>
          <Route path="/products/:category1" element={ <ProductsSub1/>}></Route>
          <Route path="/products/:category1/:category2" element={ <ProductsSub2/>}></Route>
          <Route path="/products/:category1/:category2/:category3" element={ <ProductsSub3/>}></Route>
          <Route path="/products/:category1/:category2/:category3/:category4" element={ <ProductsSub4/>}></Route>
          <Route path="/products/:category1/:category2/:category3/:category4/:category5" element={ <ProductsSub5/>}></Route>
          <Route path="/product-detail/:id" element={ <ProductDetail/>}></Route>

          <Route path="/admin" element={ <AdminPanel />}></Route>
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          <Route path="/dashboard/category/:category1" element={<Protected Component={DashboardOffer} />}></Route>
        <Route path="/dashboard/create" element={<Protected Component={CreateUser} />}></Route>
            <Route path="/dashboard/orders" element={<Protected Component={DashboardOrders} />} />
            <Route path="/dashboard/contact" element={<Protected Component={DashboardContact} />} />
            <Route path="/dashboard/:id/edit" element={<Protected Component={EditUser} />} />
        </Route>
      </Route>
    )
  );
   
  function App() {

    
  
    return (
      <div className="font-bodyFont">
        <RouterProvider router={router} />
      </div>
    );
  }
  
  export default App;
  
  
  