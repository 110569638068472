import React from 'react';
import './About.css';
import { Carousel2, category1, certificates, logo, why1, why2, why3, why4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';


const WhyUs = () => {

        const { t, i18n } = useTranslation();

  return (
   <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
    <div className='h-[150px] bg-[#F4F0E6] pl-[72px] pt-[42px]'>
   <p className='text-[#101621] text-[46px] font-bold'>{t("Niyə bizi seçməlisiniz?")}</p>
    </div>
    <div className='grid md:grid-cols-4 gap-6 mt-8'>
    <div className='md:w-[264px] md:h-[202px] rounded border border-[#eaeaeb] p-5'>
  <img className='w-[68px] h-[68px] ' src={why1} alt="Category" />
  <p className='mt-5 text-[#575a5f] text-2xl font-semibold'>{t("Təhlükəsiz alış-veriş")}</p>
</div>
<div className='md:w-[264px] md:h-[202px] rounded border border-[#eaeaeb] p-5'>
  <img className='w-[68px] h-[68px] ' src={why2} alt="Category" />
  <p className='mt-5 text-[#575a5f] text-2xl font-semibold'>{t("Böyük məhsul çeşidi")}</p>
</div>
<div className='md:w-[264px] md:h-[202px] rounded border border-[#eaeaeb] p-5'>
  <img className='w-[68px] h-[68px] ' src={why3} alt="Category" />
  <p className='mt-5 text-[#575a5f] text-2xl font-semibold'>{t("Yüksək keyfiyyətli iş")}</p>
</div>
<div className='md:w-[264px] md:h-[202px] rounded border border-[#eaeaeb] p-5'>
  <img className='w-[68px] h-[68px] ' src={why4} alt="Category" />
  <p className='mt-5 text-[#575a5f] text-2xl font-semibold'>{t("Sürətli göndərmə")}</p>
</div>
    </div>
   </div>
  );
}

export default WhyUs;
