import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";


const languageOptions = [
  { value: "az", label: "AZ",  },
  { value: "ru", label: "RU", },
  { value: "en", label: "EN", },
];

i18n.use(initReactI18next).init({
  resources: {
      az: { translation: azTranslation },
      ru: { translation: rusTranslation },
      en: { translation: enTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const AboveHeader = () => {


    const { t, i18n } = useTranslation();
      const [isOpen, setIsOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
      const savedLang = localStorage.getItem("language") || "az";
      return languageOptions.find(lang => lang.value === savedLang) || languageOptions[0];
    });
    
  
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };
  
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        i18n.changeLanguage(language.value);
        localStorage.setItem("language", language.value);
        window.location.reload()
    };
  

  return (
    <div className='bg-[#F4F4F4] hidden md:block'>
        <div className='max-w-containerSm md:max-w-containerBig md:px-4  mx-auto h-[45px] py-[10px] flex justify-end '>
       <div className='flex gap-10'>
      
       <p>+994 12 460 75 00</p>
       <p className='text-[#4285F4]'>info@yutel.az</p>
       <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
          <button
    onClick={toggleDropdown}
    className="flex items-center space-x-2 px-4 py-2 text-[#B8B8B8]"
  >
    <span>{selectedLanguage.label}</span>
    <i className="fas fa-chevron-down text-[#B8B8B8]"></i>
  </button>
 
            {isOpen && (
              <div className="absolute right-[2px] top-10 mt-2 bg-[white] text-[#B8B8B8] w-20 rounded-md shadow-lg border border-black ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#EDEDED]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
       </div>
        </div> 
    </div>
  )
}

export default AboveHeader