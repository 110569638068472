import React from 'react';
import { about, about1, about2, certificates, hero1, hero2, hero9 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const About = () => {

    const { t, i18n } = useTranslation();


  return (
    <>
        <div
              className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[400px] relative"
              style={{
                backgroundImage: `url(${hero9})`,
              }}
            >
              <div className="absolute inset-0 bg-blue-500 opacity-50"></div> {/* Mavi renkli katman */}
              <p className="text-2xl md:text-6xl font-bold text-white max-w-containerSm md:max-w-container mx-auto pt-[93px] md:pt-[170px] relative z-20">
                {t("Haqqımızda")}
              </p>
            </div>
      <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px] mb-[104px] md:mb-[160px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div className='md:w-[497px]'>
      <p className='text-[#101621] text-[50px] font-bold mt-[72px]'>{t("Şirkət haqqında")}</p>
      <p className='mt-[48px] text-[#101621] text-base font-semibold'>
      {t("YUTEL MMC 2014-ci ildən etibarən Azərbaycanın informasiya və əməliyyat texnologiyaları sahəsində fəaliyyət göstərir. Müxtəlif sənaye sahələri üçün inteqral informasiya sistemlərinin tətbiqində sübut olunmuş təcrübəmiz var. Həllərimiz standart dizaynın təmin edilmiş etibarlılığını hər bir müştərinin spesifik tələbləri ilə birləşdirir. Həllərimizin yüksək performansına və səmərəliliyinə, layihələrin vaxtında tamamlanmasına və yüksək səviyyəli xidmətə əmin ola bilərsiniz.")}
         <br></br> <br></br>  
         {t("YUTEL şirkətinin işçi qrupu olaraq məhsul və xidmət çeşidini dinamik genişləndirir, informasiya texnologiyaları bazarının tələblərinə uyğun olaraq müştərilərimizə ən yaxşı həlləri təqdim edirik. Yüksək texnologiya, qabaqcıl avadanlıqlar, peşəkar kadrlar və yüksək səviyyəli xidmət uğurumuzun əsasını təşkil edir. Etibarlı bir komanda olaraq nüfuz qazanmışıq. Müştərilərimizlə əməkdaşlıqdan fayda əldə etmiş, bizi daimi tərəfdaşlarına çevirmiş böyük hökumət layihələrini uğurla tamamlamışıq.")}
      </p>
      <a className='mt-[48px]' href='/products'>
        <button className='mt-[42px] h-[50px] bg-[#4285f4] rounded text-white px-4'>{t("Məhsullara bax")}</button>
      </a>
    </div>

    <div className="relative md:w-[553px] mt-[80px] md:mt-0 h-[620px]">
      <img
        className="absolute bottom-0 left-0 w-[249px] md:w-[383px] h-[469px] z-10"
        src={about1}
        alt="About 1"
      />
      <img
        className="absolute top-0 right-0 w-[222px] md:w-[340px] h-[340px] z-20"
        src={about2}
        alt="About 2"
      />
    </div>
  </div>
</div>

    </>
  );
};

export default About;
