import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { translateText } from "../../translate";

// Constants for better maintainability
const FIELD_TYPES = {
  QUESTION: 'question',
  ANSWER: 'answer',
  DESCRIPTION: 'description',
  APPLY: 'apply'
};

const API_ENDPOINT = "https://www.yutel.az/yutel/dashboard.php";
const IMAGE_BASE_URL = "https://www.yutel.az/yutel/";
const DATASHEET_BASE_URL = "https://yutel.az/yutel/datasheets/";

// Helper function to filter and map product fields
const getProductFields = (product, fieldPrefix) => {
  if (!product) return [];
  
  return Object.keys(product)
    .filter(key => 
      key.startsWith(fieldPrefix) && 
      product[key] && 
      String(product[key]).trim() !== ''
    )
    .map((key, index) => ({
      value: product[key],
      id: index,
      key // Store original key for translation matching
    }));
};

export default function ProductDetail() {
  const { t } = useTranslation();
  const { id, brand } = useParams();
  const language = localStorage.getItem("language") || "az";
  
  // State management
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('description');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [translatedData, setTranslatedData] = useState({});

  // Memoized product data extraction
  const questions = useMemo(() => getProductFields(product, FIELD_TYPES.QUESTION), [product]);
  const descriptions = useMemo(() => getProductFields(product, FIELD_TYPES.DESCRIPTION), [product]);
  const applyFields = useMemo(() => getProductFields(product, FIELD_TYPES.APPLY), [product]);

  // Fetch product data
  const fetchProductData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(API_ENDPOINT, { withCredentials: false });
      
      const currentProduct = data.data.find(p => p.id == id);
      const related = data.data
        .filter(p => p.brand === brand && p.id != id) // Exclude current product
        .slice(0, 4);
      
      setProduct(currentProduct);
      setRelatedProducts(related);
      
      // Debug log
      console.log('Loaded product data:', {
        product: currentProduct,
        descriptions: getProductFields(currentProduct, FIELD_TYPES.DESCRIPTION)
      });
    } catch (error) {
      console.error("Product fetch error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id, brand]);

  // Translation handler
  const handleTranslations = useCallback(async () => {
    if (!product) return;

    try {
      const translationMap = {};
      const promises = [];

      // Collect all translatable fields
      [FIELD_TYPES.QUESTION, FIELD_TYPES.ANSWER, FIELD_TYPES.DESCRIPTION, FIELD_TYPES.APPLY].forEach(prefix => {
        getProductFields(product, prefix).forEach(field => {
          promises.push(
            translateText(field.value, language)
              .then(translated => ({ key: field.key, translated }))
              .catch(() => ({ key: field.key, translated: field.value })) // Fallback
          );
        });
      });

      // Process translations
      const results = await Promise.all(promises);
      results.forEach(({ key, translated }) => {
        translationMap[key] = translated;
      });

      setTranslatedData(translationMap);
    } catch (error) {
      console.error("Translation error:", error);
      // Create fallback with original values
      const fallback = {};
      [FIELD_TYPES.QUESTION, FIELD_TYPES.ANSWER, FIELD_TYPES.DESCRIPTION, FIELD_TYPES.APPLY].forEach(prefix => {
        getProductFields(product, prefix).forEach(field => {
          fallback[field.key] = field.value;
        });
      });
      setTranslatedData(fallback);
    }
  }, [product, language]);

  // Effects
  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  useEffect(() => {
    if (product) {
      handleTranslations();
    }
  }, [product, language, handleTranslations]);

  // Get translated text with fallback
  const getTranslatedText = useCallback((key) => {
    return translatedData[key] || product?.[key] || '';
  }, [translatedData, product]);

  // Tab components
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'description':
        return (
          <div className="p-5 rounded-md bg-white">
            <h2 className="text-xl font-bold mb-4">{t("Məhsulun təsviri")}</h2>
            {descriptions.length > 0 ? (
              descriptions.map((desc) => (
                <p key={`desc-${desc.id}`} className="mb-3">
                  {getTranslatedText(desc.key)}
                </p>
              ))
            ) : (
              <p className="text-gray-500">{t("Açıklama bilgisi bulunamadı")}</p>
            )}
            {product?.description && (
              <p className="mb-3">{getTranslatedText('description')}</p>
            )}
          </div>
        );
      
      case 'apply':
        return (
          <div className="p-5 rounded-md bg-white">
            <h2 className="text-xl font-bold mb-4">{t("Tətbiqi")}</h2>
            {applyFields.length > 0 ? (
              applyFields.map((apply) => (
                <p key={`apply-${apply.id}`}>{getTranslatedText(apply.key)}</p>
              ))
            ) : (
              <p className="text-gray-500">{t("Tətbiq bilgisi bulunamadı")}</p>
            )}
          </div>
        );
      
      case 'technical':
        return (
          <div className="p-5 rounded-md bg-white">
            <h2 className="text-xl font-bold mb-4">{t("Texniki göstəricilər")}</h2>
            {questions.length > 0 ? (
              questions.map((q) => (
                <p key={`tech-${q.id}`}>
                  <span>{getTranslatedText(q.key)}</span> - 
                  <span>{getTranslatedText(q.key.replace('question', 'answer'))}</span>
                </p>
              ))
            ) : (
              <p className="text-gray-500">{t("Texniki bilgi bulunamadı")}</p>
            )}
          </div>
        );
      
      case 'information':
        return product?.datasheet ? (
          <div className="p-5 rounded-md bg-white">
            <h2 className="text-xl font-bold mb-4">{t("Məlumat cədvəli")}</h2>
            <a 
              href={`${DATASHEET_BASE_URL}${product.datasheet}`} 
              target="_blank" 
              rel="noopener noreferrer"
              download
              className="inline-block bg-[#4285f4] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            >
              {t("Yüklə")}
            </a>
          </div>
        ) : (
          <div className="p-5 rounded-md shadow-md bg-white">
            <p className="text-gray-500">{t("Datasheet bulunamadı")}</p>
          </div>
        );
      
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        {t("Ürün bulunamadı")}
      </div>
    );
  }

  return (
    <div className="bg-[#F7FAFC] min-h-screen pb-20">
      <div className="max-w-containerSm md:max-w-containerBig mx-auto pt-20 px-4">
        {/* Product Main Section */}
        <div className="flex flex-col md:flex-row w-full bg-white rounded-lg shadow-md overflow-hidden">
          {/* Product Image */}
          <div className="w-full md:w-1/2 p-6 flex justify-center items-center">
            <img
              src={`${IMAGE_BASE_URL}${product.image1}`}
              alt={product.name}
              className="max-h-[500px] object-contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/placeholder-product.png';
              }}
            />
          </div>
          
          {/* Product Info */}
          <div className="w-full md:w-1/2 p-6">
            <h1 className="text-2xl md:text-3xl font-bold mb-6">{product.name}</h1>
            
            {/* Questions & Answers Table */}
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-200">
                <tbody>
                  {questions.map((q) => (
                    <tr key={`qa-${q.id}`} className="even:bg-gray-50">
                      <td className="text-gray-600 p-3 border border-gray-200 font-medium">
                        {getTranslatedText(q.key)}
                      </td>
                      <td className="p-3 border border-gray-200">
                        {getTranslatedText(q.key.replace('question', 'answer'))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        {/* Product Tabs */}
        <div className="mt-8 bg-white rounded-lg shadow-md overflow-hidden">
          <div className="flex overflow-x-auto border-b">
            {['description', 'apply', 'technical', 'information'].map((tab) => (
              <button
                key={tab}
                className={`px-6 py-3 font-medium whitespace-nowrap ${
                  selectedTab === tab 
                    ? 'border-b-2 border-blue-500 text-blue-600' 
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {t(
                  tab === 'description' ? "Məhsulun təsviri" :
                  tab === 'apply' ? "Tətbiq sahəsi" :
                  tab === 'technical' ? "Texniki göstəricilər" :
                  "Məlumat cədvəli"
                )}
              </button>
            ))}
          </div>
          
          <div className="p-6">
            {renderTabContent()}
          </div>
        </div>
        
        {/* Related Products */}
        {relatedProducts.length > 0 && (
          <div className="mt-12 bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-8 text-center">
              {t("Bənzər məhsullar")}
            </h2>
            
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
              {relatedProducts.map((related) => (
                <div 
                  key={related.id}
                  className="border border-gray-200 rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
                >
                  <a href={`/product-detail/${related.id}`} className="block">
                    <div className="p-4">
                      <div className="h-64 flex items-center justify-center">
                        <img
                          src={`${IMAGE_BASE_URL}${related.image1}`}
                          alt={related.name}
                          className="max-h-full max-w-full object-contain"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/placeholder-product.png';
                          }}
                        />
                      </div>
                      <h3 className="mt-4 text-lg font-semibold text-center">
                        {related.name}
                      </h3>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}