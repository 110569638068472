import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function PaginatedItems({ itemsPerPage, items }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='md:max-w-container max-w-containerSm mx-auto grid grid-cols-1 md:grid-cols-4 gap-4 p-4'>
        {currentItems && currentItems.map((item, index) => (
          <div className='bg-white w-[307px] min-h-[407px] mx-auto md:mx-0 mt-5 shadow rounded' key={index}>
          <a href={`/product-detail/${item.id}`}>
            <div className='m-3 text-[black]'>
              <div className='rounded-xl h-[308px] mb-3 flex justify-center items-center'>
                <img className='mx-auto w-[247px] h-[247px]' src={`https://www.yutel.az/yutel/${item.image1}`} alt='' />
              </div>
              <div className='flex flex-col'>
              <p className='text-left text-[16px] md:text-[18px] font-medium'>  {item.name}</p>
              </div>
            </div>
          </a>
        </div>
        ))} 
      </div>

      <ReactPaginate
        nextLabel="sonrakı"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="əvvəlki"
        renderOnZeroPageCount={null}
        containerClassName="flex justify-center items-center space-x-1 my-4"
        pageClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        previousClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        nextClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        activeClassName="bg-blue-600 text-white"
        disabledClassName="text-gray-400 cursor-not-allowed"
      />
    </>
  );
}

export default PaginatedItems;

