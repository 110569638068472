import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SingleComponent = () => {

    const { t, i18n } = useTranslation(); 
  const [openCategories, setOpenCategories] = useState([]);

  const categories = [

       //Category 1
    {
      name: t("UPS"),
      link: "/ups",
      subcategories: [
        { name: t("3 Faza Giriş - 1 Faza Çıxış"), link: "/ups/3faza-1faza" },
        { name: t("3 Faza Giriş - 3 Faza Çıxış"), link: "/ups/3faza-3faza" },
        { name: t("Tək fazalı"), link: "/ups/tekfazali" },
      ],
    },
  

       //Category 2 
    {
      name: t("Fiber Optik"),
      link: "/fiber-optik",
      subcategories: [
        {
          name: t("Komponentlər"),
          link: "/fiber-optik/komponentler",
          subcategories: [
            { name: t("Adaptorlar & Konnektorlar"), link: "/fiber-optik/komponentler/adaptorlar" },
            {
              name: t("Patch Kordlar"),
              link: "/fiber-optik/komponentler/patch-kordlar",
              subcategories: [
                { name: t("İndoor"), link: "/fiber-optik/komponentler/patch-kordlar/indoor" },
                { name: t("Outdoor"), link: "/fiber-optik/komponentler/patch-kordlar/outdoor" },
              ],
            },
            { name: t("Fiber Optik Pitgaillər"), link: "/fiber-optik/komponentler/pitgailler" },
            { name: t("Splitterlər"), link: "/fiber-optik/komponentler/splitterler" },
          ],
        },
        { name: t("MDC"), link: "/fiber-optik/mdc" },
        {
          name: t("MTP-MPO"),
          link: "/fiber-optik/mtp-mpo",
          subcategories: [
            { name: t("MTP-MTO Komponentlər"), link: "/fiber-optik/mtp-mpo/komponentler" },
            { name: t("MTP-MTP Patch Panellər"), link: "/fiber-optik/mtp-mpo/patch-paneller" },
          ],
        },
        {
            name: t("Fiber optik kabinlər"),
            link: "/fiber-optik/fiber-optik-kabinler",
            subcategories: [
                {
                    name: t("Kabinlər"),
                    link: "/fiber-optik/fiber-optik-kabinler/kabinler",
                    subcategories: [
                      { name: t("İndoor"), link: "/fiber-optik/fiber-optik-kabinler/kabinler/indoor" },
                      { name: t("Outdoor"), link: "/fiber-optik/fiber-optik-kabinler/kabinler/outdoor" },
                    ],
                  },
                  {
                    name: t("Paylayıcı Qutular"),
                    link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular",
                    subcategories: [
                        {
                            name: t("İndoor (Paylayıcı Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/paylayici/metal" },
                            ],
                          },
                          {
                            name: t("İndoor (Splitter Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/splitter/metal" },
                            ],
                          },
                          {
                            name: t("İndoor (Terminal Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/terminal/metal" },
                            ],
                          },
                          {
                            name: t("Outdoor (Paylayıcı Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-paylayici/metal" },
                            ],
                          },
                          {
                            name: t("Outdoor (Splitter Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-splitter/metal" },
                            ],
                          },
                          {
                            name: t("Outdoor (Terminal Qutular)"),
                            link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal",
                            subcategories: [
                              { name: t("Plastik"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal/plastik" },
                              { name: t("Metal"), link: "/fiber-optik/fiber-optik-kabinler/paylayici-qutular/outdoor-terminal/metal" },
                            ],
                          },
                    ],
                  },
                  {
                    name: t("Muftalar"),
                    link: "/fiber-optik/fiber-optik-kabinler/muftalar",
                    subcategories: [
                        {
                            name: t("(Dome) Muftalar"),
                            link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome",
                            subcategories: [
                              { name: t("(Heat Shrink) Muftalar"), link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome/heat" },
                              { name: t("(Mechanical) Muftalar"), link: "/fiber-optik/fiber-optik-kabinler/muftalar/dome/mechanical" },
                            ],
                          },
                          {
                            name: t("(İnline) Muftalar"),
                            link: "/fiber-optik/fiber-optik-kabinler/muftalar/inline",
                            subcategories: [
                              { name: t("İnline Mechanical"), link: "/fiber-optik/fiber-optik-kabinler/muftalar/inline/mechanical" },
                            ],
                          },
                          {
                            name: t("(FTTH BPEO) Muftalar"),
                            link: "/fiber-optik/fiber-optik-kabinler/muftalar/ffth",
                          },
                          
                    ],
                  },
                  {
                    name: t("Patch Panellər"),
                    link: "/fiber-optik/fiber-optik-kabinler/patch-paneller",
                    subcategories: [
                      { name: t("1U"), link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/1u" },
                      { name: t("2U"), link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/2u" },
                      { name: t("3U"), link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/3u" },
                      { name: t("4U"), link: "/fiber-optik/fiber-optik-kabinler/patch-paneller/4u" },
                    ],
                  },
                  {
                    name: t("Aksesuarlar"),
                    link: "/fiber-optik/fiber-optik-kabinler/aksesuarlar",
                  },
            ],
          },
          {
            name: t("Aktiv"),
            link: "/fiber-optik/aktiv",
            subcategories: [
              { name: t("FTTH Aktiv Avadanlıqlar"), link: "/fiber-optik/aktiv/ffth" },
              { name: t("OEO"), link: "/fiber-optik/aktiv/oeo" },
              { name: t("PoE"), link: "/fiber-optik/aktiv/poe" },
              { name: t("Media Konverter Şassiləri"), link: "/fiber-optik/aktiv/media" },
              { name: t("SFB Transiverlər"), link: "/fiber-optik/aktiv/sfb" },
              { name: t("Tək Lifli Fiber RJ45"), link: "/fiber-optik/aktiv/tek-lifli" },
              { name: t("Cüt Lifli Fiber RJ45"), link: "/fiber-optik/aktiv/cut-lifli" },
              { name: t("Svitçlər"), link: "/fiber-optik/aktiv/svitcler" },
            ],
          },
          {
            name: t("Alətlər/Avadanlıqlar"),
            link: "/fiber-optik/alet-avadanliq",
            subcategories: [
              { name: t("Təmizləyici Alətlər"), link: "/fiber-optik/alet-avadanliq/temizleyici-alet" },
              {
                name: t("FO Alətlər"),
                link: "/fiber-optik/alet-avadanliq/fo-aletler",
                subcategories: [
                  { name: t("Sıxıcı Alətlər"), link: "/fiber-optik/alet-avadanliq/fo-aletler/sixici" },
                  { name: t("Parçalayıcı Alətlər"), link: "/fiber-optik/alet-avadanliq/fo-aletler/parcalayici" },
                  { name: t("Fiber Alignment"), link: "/fiber-optik/alet-avadanliq/fo-aletler/fiber" },
                  { name: t("Kəsici Alətlər"), link: "/fiber-optik/alet-avadanliq/fo-aletler/kesici" },
                  { name: t("OTDR"), link: "/fiber-optik/alet-avadanliq/fo-aletler/otdr" },
                  { name: t("Mikroskop"), link: "/fiber-optik/alet-avadanliq/fo-aletler/mikroskop" },
                  { name: t("İdentifikator"), link: "/fiber-optik/alet-avadanliq/fo-aletler/identifikator" },
                  { name: t("Güc Ölçən"), link: "/fiber-optik/alet-avadanliq/fo-aletler/guc-olcen" },
                  { name: t("VFL"), link: "/fiber-optik/alet-avadanliq/fo-aletler/vfl" },
                  { name: t("Splice Machines"), link: "/fiber-optik/alet-avadanliq/fo-aletler/splice" },
                  { name: t("Alət dəstləri"), link: "/fiber-optik/alet-avadanliq/fo-aletler/alet-destleri" },
                  
                ],
              },
              { name: t("Fiber Optik Pitgaillər"), link: "/fiber-optik/alet-avadanliq/pitgailler" },
              { name: t("Splitterlər"), link: "/fiber-optik/alet-avadanliq/splitterler" },
            ],
          },
      ],
    },


           //Category 3
    {
        name: t("Copper (Mis)"),
        link: "/copper",
        subcategories: [
          {
            name: t("Kabellər"),
            link: "/copper/kabeller",
            subcategories: [
                {
                    name: t("Cat 8"),
                    link: "/copper/kabeller/cat8",
                    subcategories: [
                      { name: t("Kabellər"), link: "/copper/kabeller/cat8/kabeller" },
                      { name: t("KSJ"), link: "/copper/kabeller/cat8/ksj" },
                      { name: t("Patch Kordlar"), link: "/copper/kabeller/cat8/patch-kordlar" },
                      { name: t("Patch Panellər"), link: "/copper/kabeller/cat8/patch-paneller" },
                      { name: t("Konnektorlar"), link: "/copper/kabeller/cat8/konnektorlar" },
                    ],
                  },
                  {
                    name: t("Cat 7"),
                    link: "/copper/kabeller/cat7",
                    subcategories: [
                      { name: t("Kabellər"), link: "/copper/kabeller/cat7/kabeller" },
                      { name: t("KSJ"), link: "/copper/kabeller/cat7/ksj" },
                      { name: t("Patch Kordlar"), link: "/copper/kabeller/cat7/patch-kordlar" },
                      { name: t("Patch Panellər"), link: "/copper/kabeller/cat7/patch-paneller" },
                      { name: t("Konnektorlar"), link: "/copper/kabeller/cat7/konnektorlar" },
                    ],
                  },
                  {
                    name: t("Cat 6a"),
                    link: "/copper/kabeller/cat6a",
                    subcategories: [
                      { name: t("Kabellər"), link: "/copper/kabeller/cat6a/kabeller" },
                      { name: t("Keystone Jacks"), link: "/copper/kabeller/cat6a/keystone" },
                      { name: t("Konnektorlar"), link: "/copper/kabeller/cat6a/konnektorlar" },
                      { name: t("Patch Kordlar"), link: "/copper/kabeller/cat6a/patch-kordlar" },
                      { name: t("Patch Panellər"), link: "/copper/kabeller/cat6a/patch-paneller" },
                    ],
                  },
                  {
                    name: t("Cat 6"),
                    link: "/copper/kabeller/cat6",
                    subcategories: [
                      { name: t("Kabellər"), link: "/copper/kabeller/cat6/kabeller" },
                      { name: t("Keystone Jacks"), link: "/copper/kabeller/cat6/keystone" },
                      { name: t("Konnektorlar"), link: "/copper/kabeller/cat6/konnektorlar" },
                      { name: t("Patch Kordlar"), link: "/copper/kabeller/cat6/patch-kordlar" },
                      { name: t("Patch Panellər"), link: "/copper/kabeller/cat6/patch-paneller" },
                    ],
                  },
                  {
                    name: t("Cat 5e"),
                    link: "/copper/kabeller/cat5e",
                    subcategories: [
                      { name: t("Kabellər"), link: "/copper/kabeller/cat5e/kabeller" },
                      { name: t("Keystone Jacks"), link: "/copper/kabeller/cat5e/keystone" },
                      { name: t("Konnektorlar"), link: "/copper/kabeller/cat5e/konnektorlar" },
                      { name: t("Patch Kordlar"), link: "/copper/kabeller/cat5e/patch-kordlar" },
                      { name: t("Patch Panellər"), link: "/copper/kabeller/cat5e/patch-paneller" },
                    ],
                  },
            ],
          },
          {
            name: t("Alətlər və Aksesuarlar"),
            link: "/copper/alet-aksesuarlar",
            subcategories: [
              { name: t("Aksesuarlar"), link: "/copper/alet-aksesuarlar/aksesuarlar" },
              { name: t("Alətlər"), link: "/copper/alet-aksesuarlar/aletler" },
              { name: t("Konnektorlar"), link: "/copper/alet-aksesuarlar/konnektorlar" },
              { name: t("Alət Çantaları"), link: "/copper/alet-aksesuarlar/alet-cantalari" },
            ],
          },
          { name: t("Patch Panellər"), link: "/copper/patch-panel" },
          { name: t("Divar tipli yuvalar"), link: "/copper/divar-tipli" },
          { name: t("PoE injektor"), link: "/copper/poe-injektor" },
        ],
      },



   //Category 4
      {
        name: t("Kabinlər"),
        link: "/kabinler",
        subcategories: [
          { name: t("Kabinlər (İndoor Floor)"), link: "/kabinler/indoor" },
          { name: t("Kabinlər (Outdoor Floor)"), link: "/kabinler/outdoor" },
          { name: t("Divar tipli kabinlər (İndoor Wall)"), link: "/kabinler/indoor-wall" },
          { name: t("Divar tipli kabinlər (Outdoor Wall)"), link: "/kabinler/outdoor-wall" },
          { name: t("PDU"), link: "/kabinler/pdu" },
          { name: t("Aksesuarlar"), link: "/kabinler/aksesuarlar" },
          { name: t("Kabel Menecmentlər"), link: "/kabinler/kabel-menecmentler" },
          { name: t("Fanlar"), link: "/kabinler/fanlar" },
          { name: t("Rəflər"), link: "/kabinler/refler" },
        ],
      },
  ];

  const toggleCategory = (name) => {
    setOpenCategories((prev) =>
      prev.includes(name)
        ? prev.filter((category) => category !== name)
        : [...prev, name]
    );
  };

  const renderCategories = (categories, level = 0) => {
    return categories.map((category, index) => (
      <div key={index} className={`mb-2 ${level > 0 ? "ml-4" : ""}`}>
        <div className="flex items-center justify-between">
          {/* Kategori Linki */}
          <a
            href={`/products${category.link}`}
            className="hover:underline" 
          >
            {category.name}
          </a>
          {/* Arrow */}
          {category.subcategories && (
            <button
              className="ml-2 text-gray-500 hover:text-gray-700"
              onClick={() => toggleCategory(category.name)}
            >
              {openCategories.includes(category.name) ? "▼" : "▶"}
            </button>
          )}
        </div>
        {/* Alt Kategoriler */}
        {openCategories.includes(category.name) && category.subcategories && (
          <div className="mt-2">
            {renderCategories(category.subcategories, level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return <div className="p-4">{renderCategories(categories)}</div>;
};

export default SingleComponent;
