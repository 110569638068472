import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import Categories from './Categories'
import { useTranslation } from "react-i18next";

const Products = () => {


    const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <>
    
    <div className="bg-[#F7FAFC] pb-[104px] md:pb-[160px]">
      <div className='max-w-containerSm md:max-w-containerBig px-4 mx-auto pt-[76px] md:pt-[108px] '>
        
        <div className='md:flex mx-auto mt-8 gap-8 justify-between'>
          {/* Left Filter Section */}
          <div className="md:w-[363px]">
            <h4 className='text-black text-xl font-medium'>{t("Məhsul kateqoriyaları")}</h4>
            <Categories/>
           
            
          </div>
      
          {/* Products Section */} 
          <div className="md:w-[1026px]">
            <h1>{t("Məhsullar")}</h1>
            {isLoading ?  <p className="text-[white]"> Loading...</p> :
              <PaginatedItems itemsPerPage={15} items={products} />  
            }
           
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Products;


















































