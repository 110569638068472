import React from 'react';
import { about, about1, about2, certificates, hero1, hero2, hero8, minus, okay, solutions1, solutions2 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const Solutions = () => {

    const { t, i18n } = useTranslation();

  return (
    <>
       <div
        className="bg-cover bg-no-repeat bg-center w-full h-[220px] md:h-[400px] relative"
        style={{
          backgroundImage: `url(${hero8})`,
        }}
      >
        <div className="absolute inset-0 bg-blue-500 opacity-50"></div> {/* Mavi renkli katman */}
        <p className="text-2xl md:text-6xl font-bold text-white max-w-containerSm md:max-w-container mx-auto pt-[93px] md:pt-[170px] relative z-20">
        {t("Həllər")}
        </p>
      </div>
      <div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div className='md:w-[497px]'>
      <p className='text-[#101621] text-3xl md:text-[50px] font-bold'>{t("YUTEL MMC")}</p>
      <p className='mt-[38px] text-[#101621] text-base font-semibold'>
      {t("YUTEL şirkətinin işçi qrupu olaraq məhsul və xidmət çeşidini dinamik genişləndirir, informasiya texnologiyaları bazarının tələblərinə uyğun olaraq müştərilərimizə ən yaxşı həlləri təqdim edirik. Yüksək texnologiya, qabaqcıl avadanlıqlar, peşəkar kadrlar və yüksək səviyyəli xidmət uğurumuzun əsasını təşkil edir. Etibarlı bir komanda olaraq nüfuz qazanmışıq. Müştərilərimizlə əməkdaşlıqdan fayda əldə etmiş, bizi daimi tərəfdaşlarına çevirmiş böyük hökumət layihələrini uğurla tamamlamışıq.")}
      </p>
      <a className='mt-[38px]' href='/contact'>
        <button className='mt-[42px] h-[50px] bg-[#4285f4] rounded text-white px-4'>{t("Bizimlə əlaqə")}</button>
      </a>
    </div>

    <div className="relative md:w-[553px] mt-[80px] md:mt-0">
      <img src={solutions1}></img>
    </div>
  </div>
</div>
<div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div className="relative md:w-[800px] mt-[80px] md:mt-0">
      <img src={solutions2}></img>
    </div>
  </div>
</div>
<div className='max-w-containerSm md:max-w-container mx-auto mt-[76px] md:mt-[108px] mb-[104px] md:mb-[160px]'>
  <div className='md:flex justify-between gap-[80px]'>
    <div>
      <p className='text-[#101621] text-3xl md:text-[50px] font-bold'>{t("Bizim ixtisaslaşmamız")}</p>
      <p className='mt-[38px] text-[#101621] text-base font-semibold'>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("Şəbəkə infrastrukturu layihələndirilməsi və quraşdırılması")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("LAN, IP/MPLS, SDH, DWDM, SDN şəbəkələri")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("Rabitə sistemləri, Zəng və Əlaqə Mərkəzi")} </p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("DATA Şəbəkə, DATA Mərkəzi")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("SAP Konsaltinq Xidmətləri")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("SAP Business Suite (Treasury and Financial Risk Management; Procurement; Supply Chain Management; Manufacturing; Customer Relationship Management) Implementation.")} </p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("SAP Industry Portfolios (Oil & Gas; Chemicals; Transportation & Logistics; Public Sector; Banking) Implementation.")} </p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("SAP NetWeaver (Master Data Management; Process Integration) Implementation.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("İT Xidmətlər:")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("Müxtəlif informasiya infrastrukturu xarakteristikalarını öyrənməyə və onların təsirini qiymətləndirməyə imkan verən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("Sistemə quraşdırılmış firewall-un təhlükəsizliyini araşdırmaq və qiymətləndirmək xidmətini əhatə edən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("Hesablama mühitinin tələb olunan effektivlik, funksionallıq və əlçatanlıq səviyyələrini nəzərə alaraq layihələndirmə xidməti.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("İnformasiya infrastrukturuna giriş və ilkin və orta səviyyəli serverlərin konfiqurasiyasını nəzərdə tutan xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("İnformasiya infrastrukturuna giriş və yüksək səviyyəli serverlərin konfiqurasiyasını əhatə edən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("İnformasiya infrastrukturuna giriş və Fujitsu, HP, IBM fəlakət davamlı serverlərinin konfiqurasiyasını əhatə edən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("Fujitsu, HP, IBM klaster həllini əhatə edən sistemin layihələndirilməsi, konfiqurasiyası və test edilməsi xidməti.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("İnformasiya sisteminin performansının qiymətləndirilməsi, eləcə də onun dəyişdirilməsi metod və vasitələrinin təqdim edilməsi xidmətini əhatə edən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("İnformasiya sisteminin performansının qiymətləndirilməsi, eləcə də onun dəyişdirilməsi metod və vasitələrinin təqdim edilməsi xidmətini əhatə edən xidmət.")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={minus}></img>
      <p className='ml-2'>{t("UNIX (AIX, zOS, Linux) əməliyyat sistemlərinin daxili vasitələri ilə sistem serverlərinin əsas təhlükəsizliyini gücləndirmə xidməti")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("FO Kabel şəbəkələri və Kabel Sistemləri")}</p>
      </div>
      <div className='flex'>
      <img className='h-6 w-6' src={okay}></img>
      <p className='ml-2'>{t("Təhlükəsizlik sistemi – CCTV, Yanğın Siqnalı, Giriş nəzarəti və Perimetr təhlükəsizlikləri")}</p>
      </div>
      </p>
    </div>
  </div>
</div>

    </>
  );
};

export default Solutions;
