import React, { useEffect, useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import PaginatedItems from './PaginatedItems';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";


const languageOptions = [
  { value: "az", label: "AZ",  },
  { value: "ru", label: "RU", },
  { value: "en", label: "EN", },
];

i18n.use(initReactI18next).init({
  resources: {
      az: { translation: azTranslation },
      ru: { translation: rusTranslation },
      en: { translation: enTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const Header = () => {

  const { t, i18n } = useTranslation();


    const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLang = localStorage.getItem("language") || "az";
    return languageOptions.find(lang => lang.value === savedLang) || languageOptions[0];
  });
  


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
};

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.yutel.az/yutel/dashboard.php`,
        { withCredentials: false }
      );
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];


  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  return (
    <>
        <div className='max-w-containerSm md:max-w-containerBig md:px-4 mx-auto h-[92px] py-[10px] flex justify-between '>
        <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0  bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px] "></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[120px]" /></a>
       <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#e10600] ' : 'text-[black]'}`} href="/"> {t("Əsas səhifə")}</a>
              <a href="/about" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Haqqımızda")}</a>
              <a href="/solutions" className={`text-base text-left font-normal ${isActiveLink('/solutions') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Həllər")}</a>
              <a href="/partners" className={`text-base text-left font-normal ${isActiveLink('/partners') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Partnyorlar")}</a>
              <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
              <a href="/products" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Məhsullar")}</a>
              <a href="/shop" className={`text-base text-left font-normal ${isActiveLink('/shop') ? 'text-[#e10600]' : 'text-[black]'}`}>{t("Mağaza")}</a>

    </div>

          </div>
          </div>
        </div>
      )} 
      </button>
          <div className='flex items-center'>
            <a className='hidden md:block' href='/'><img alt='logo' height={47} width={223} src={logo}></img></a>
          </div>
          <div className='md:flex items-center w-[740px] hidden'>
          <nav>
  <ul className="flex justify-between ">
    <a className='mr-[36px]' href="/">
      <li>{t("Əsas səhifə")}</li>
    </a>
    <a className='mr-[36px]' href="/about">
      <li>{t("Haqqımızda")}</li>
    </a>
    <a className='mr-[36px]' href="/solutions">
      <li>{t("Həllər")}</li>
    </a>
    <a className='mr-[36px]' href="/partners">
      <li>{t("Partnyorlar")}</li>
    </a>
    <a className='mr-[36px]' href="/contact">
      <li>{t("Əlaqə")}</li>
    </a>
    <a className='mr-[36px]' href="/products">
      <li>{t("Məhsullar")}</li>
    </a>
    <a href="/shop">
      <li>{t("Mağaza")}</li>
    </a>
  </ul>
</nav>

          </div>

          <div className="flex items-center justify-end relative">
          {/* Arama Butonu ve Overlay */}
          <div className="flex justify-center items-center">
              <div className="relative right-20 md:right-0">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder= {t("Axtar...")}
                  className="p-2 rounded w-[215px] border-[#595959] border-2 text-black"
                />
              </div>
            </div>

        </div>
      </div>
      {searchQuery && (
        <div className="absolute top-0 left-0 w-full bg-white z-50 mt-[130px]">
          {isLoading ? (
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItems itemsPerPage={15} items={filteredProducts} />
          )}
        </div>
      )}
    </>
  );
};

export default Header;



